import classnames from 'classnames';
import React from 'react';

import searchIconPath from '@/assets/images/search.svg';
import { AsComponent } from '@/components/As';

import { Input, Props } from './Input';

export const SearchInput = React.forwardRef(function SearchInput<T extends AsComponent>(
  props: Props<T>,
  ref: React.Ref<any>
) {
  return (
    <label className={classnames('relative', props.className)} htmlFor={props.id}>
      <Input {...props} {...{ ref }} className="pl-10" type="text" />

      <img
        alt="Search"
        className="pointer-events-none absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2"
        src={searchIconPath}
      />
    </label>
  );
});
