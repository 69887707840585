import React from 'react';
import { Avatar } from './Avatar';
import { Badge } from './Badge';

export type Props = {
  firstName?: string;
  lastName?: string;
  email: string;
  src?: string;
  groups?: string[];
  birthDate?: string | null;
  plan?: string;
  status?: string;
  shouldShowDetailedProfile?: boolean;
};

export const MemberProfile: React.FC<Props> = ({
  firstName,
  lastName,
  email,
  src,
  groups,
  birthDate,
  plan,
  status,
  shouldShowDetailedProfile = false
}) => {
  return shouldShowDetailedProfile ? (
    <div className="inline-flex items-center">
      <Avatar {...{ src }} alt="profile-image" size="2xl" />

      <div className="flex flex-col gap-2 px-4">
        <div className="flex items-center gap-2">
          <span className="font-medium text-neutral-800">
            {firstName} {lastName}
          </span>
          <Badge appearance={status as any} className="capitalize">
            {status}
          </Badge>
        </div>
        <div className="flex items-center gap-2">
          <Badge appearance={plan as any} className="capitalize">
            {plan}
          </Badge>
        </div>

        {groups && (
          <div className="flex items-center gap-2 text-sm">
            {groups.map((group, index) => (
              <Badge appearance="group" className="capitalize" key={index}>
                {group}
              </Badge>
            ))}
          </div>
        )}

        <div className="flex items-center gap-2 text-sm">
          <span className="text-neutral-500">{email}</span>
          {birthDate && <span className="text-neutral-500">| {new Date(birthDate).toISOString().split('T')[0]}</span>}
        </div>
      </div>
    </div>
  ) : (
    <div className="inline-flex items-center">
      <Avatar {...{ src }} alt="profile-image" size="lg" />

      <div className="flex flex-col px-4">
        <span className="font-medium text-neutral-800">
          {firstName} {lastName}
        </span>

        <span className="text-neutral-500">{email}</span>
      </div>
    </div>
  );
};
