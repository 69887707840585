import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { LoadingButton } from '@/components/LoadingButton';
import { InstanceProps } from '@/components/Modal/Modal';
import { ModalHeader } from '@/components/Modal/ModalHeader';
import { ModalLayout } from '@/components/Modal/ModalLayout';
import { translations } from '@/locales';

const schema = yup
  .object({
    name: yup.string().trim().required().label(translations.fields.groupName)
  })
  .required();

interface ModalProps extends InstanceProps<object | null> {
  onCreateGroup(name: string): Promise<void>;
}

export const CreateGroupModal: React.FC<ModalProps> = ({ onClose, onCreateGroup, className }) => {
  const intl = useIntl();

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      await onCreateGroup(values.name);
      onClose();
    },
    [onClose, onCreateGroup]
  );

  return (
    <ModalLayout {...{ className }}>
      <ModalHeader>
        <FormattedMessage id={translations.pages.groups.newGroup} />
      </ModalHeader>

      <Form {...{ onSubmit, schema }}>
        {({ submitting }) => (
          <div className="space-y-6">
            <ValidatedField
              field={InputWithLabel}
              id="name"
              name="name"
              placeholder={intl.formatMessage({ id: translations.fields.groupName })}
              readOnly={submitting}
            />

            <div className="flex gap-4">
              <Button appearance="basicSecondary" className="w-full" onClick={onClose} type="button">
                <FormattedMessage id={translations.buttons.cancel} />
              </Button>

              <Button appearance="primary" as={LoadingButton} className="w-full" loading={submitting} type="submit">
                <FormattedMessage id={translations.buttons.addGroup} />
              </Button>
            </div>
          </div>
        )}
      </Form>
    </ModalLayout>
  );
};
