import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';
import * as yup from 'yup';

import { translations } from '@/locales';

import { apiClient } from '@/services/api';

import { Button } from '@/components/Button';
import { CenteredBoxLayout } from '@/components/CenteredBoxLayout';
import { Form } from '@/components/Form';
import { PasswordInput } from '@/components/Form/PasswordInput';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { LoadingButton } from '@/components/LoadingButton';

import backgroundImg from '@/assets/background-reset-password.png';
import { Input } from '@/components/Form/Input';
import { Modal } from '@/components/Modal';
import { SuccessModal } from '@/components/Modal/SuccessModal';
import { useModal } from '@/hooks/useModal';

export const schema = yup
  .object({
    email: yup.string().trim().email().required().label(translations.fields.emailAddress),
    firstName: yup.string().trim().required().label(translations.fields.firstName),
    lastName: yup.string().trim().required().label(translations.fields.lastName),
    password: yup.string().trim().required().label(translations.fields.password),
    repeatPassword: yup
      .string()
      .trim()
      .oneOf([yup.ref('password')], translations.customValidation.matchPasswords)
      .required()
      .label(translations.fields.repeatPassword)
  })
  .required();

export const RegisterPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const successModal = useModal(SuccessModal);

  const params = new URLSearchParams(location.search);

  const [token, email] = [params.get('token'), params.get('email')];

  React.useEffect(() => {
    if (!token) navigate('/expired');
  }, []);

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      console.log(values);

      await apiClient.onboarding
        .signUp({ requestBody: { ...values, token: token! } })
        .then(() => successModal.open().catch(() => null));
    },
    [token]
  );

  return (
    <CenteredBoxLayout
      backgroundImg={backgroundImg}
      subhead={<FormattedMessage id={translations.pages.register.subhead} />}
      title={<FormattedMessage id={translations.pages.register.title} />}
    >
      <Form initialValues={{ email: email! }} {...{ onSubmit, schema }}>
        {({ submitting, submitError }) => (
          <div className="space-y-4">
            <div className="flex gap-2">
              <ValidatedField
                className="w-full"
                field={Input}
                id="firstName"
                label={<FormattedMessage id={translations.fields.firstName} />}
                name="firstName"
                placeholder="First Name"
                readOnly={submitting}
                type="text"
              />

              <ValidatedField
                className="w-full"
                field={Input}
                id="lastName"
                label={<FormattedMessage id={translations.fields.lastName} />}
                name="lastName"
                placeholder="Last Name"
                readOnly={submitting}
                type="text"
              />
            </div>
            <ValidatedField
              field={PasswordInput}
              id="password"
              label={<FormattedMessage id={translations.fields.password} />}
              name="password"
              placeholder="New password"
              readOnly={submitting}
              type="password"
            />

            <ValidatedField
              field={PasswordInput}
              id="repeatPassword"
              label={<FormattedMessage id={translations.fields.repeatPassword} />}
              name="repeatPassword"
              placeholder="Repeat the password"
              readOnly={submitting}
              type="password"
            />

            <Button appearance="primary" as={LoadingButton} className="w-full" loading={submitting} type="submit">
              <FormattedMessage id={translations.pages.register.cta} />
            </Button>

            {submitError && navigate('/expired')}
          </div>
        )}
      </Form>

      <Modal
        subhead={<FormattedMessage id={translations.pages.register.modalSubhead} />}
        title={<FormattedMessage id={translations.pages.register.modalTitle} />}
        {...successModal.props}
        onClose={() => null}
      />
    </CenteredBoxLayout>
  );
};
