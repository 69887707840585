import React from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { Auth } from './containers/use-auth';
import { useRole } from './hooks/useRole';

import { CommunitiesPage } from './pages/Admin/CommunitiesPage';
import { CreateCommunityPage } from './pages/Admin/CreateCommunity';
import { QuestionDetailsPage } from './pages/Admin/QuestionDetailsPage';
import { QuestionsPage } from './pages/Admin/QuestionsPage';
import { ExpiredTokenPage } from './pages/AuthPages/ExpiredTokenPage';
import { ForgotPasswordPage } from './pages/AuthPages/ForgotPasswordPage';
import { LoginPage } from './pages/AuthPages/LoginPage';
import { RegisterPage } from './pages/AuthPages/RegisterPage';
import { ResetPasswordPage } from './pages/AuthPages/ResetPasswordPage';
import { BenefitDetailsPage } from './pages/Benefits/BenefitDetailsPage';
import { BenefitsPage } from './pages/Benefits/BenefitsPage';
import { CommunityPageDetails } from './pages/CommunityDetailPage';
import { EventDetailsPage } from './pages/Events/EventDetailsPage';
import { EventsPage } from './pages/Events/EventsPage';
import { CreateGroupPage } from './pages/Groups/CreateGroupPage';
import { GroupDetailsPage } from './pages/Groups/GroupDetailsPage';
import { GroupsPage } from './pages/Groups/GroupsPage';
import { ImportMembersPage } from './pages/Members/ImportMembers';
import { MemberDetailsPage } from './pages/Members/MemberDetailsPage';
import { MembersPage } from './pages/Members/MembersPage';
import { MessageDetailsPage } from './pages/Messages/MessageDetailsPage';
import { MessagesPage } from './pages/Messages/MessagesPage';
import { MyProfilePage } from './pages/ProfilePage';
import { ServiceDetailsPage } from './pages/Services/ServiceDetailsPage';
import { ServicesPage } from './pages/Services/ServicesPage';

export const App: React.FC = () => {
  const { authenticated, context } = Auth.useContainer();
  const { isAdmin, community } = useRole();

  return (
    <Routes>
      {authenticated ? (
        <React.Fragment>
          {!!context && (
            <React.Fragment>
              {isAdmin ? (
                <React.Fragment>
                  <Route element={<QuestionsPage />} path="/questions/" />
                  <Route element={<QuestionDetailsPage />} path="/questions/new" />
                  <Route element={<QuestionDetailsPage />} path="/questions/:questionId" />

                  <Route element={<CommunitiesPage />} path="/communities" />
                  <Route element={<CreateCommunityPage />} path="/communities/new" />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Route element={<ImportMembersPage />} path="/import-members" />

                  <Route element={<ServicesPage />} path="/services" />
                  <Route element={<ServiceDetailsPage />} path="/services/new" />
                  <Route element={<ServiceDetailsPage />} path="/services/:serviceId" />

                  <Route element={<EventsPage />} path="/events" />
                  <Route element={<EventDetailsPage />} path="/events/new" />
                  <Route element={<EventDetailsPage />} path="/events/:eventId" />

                  <Route element={<BenefitsPage />} path="/benefits" />
                  <Route element={<BenefitDetailsPage />} path="/benefits/new" />
                  <Route element={<BenefitDetailsPage />} path="/benefits/:benefitId" />
                </React.Fragment>
              )}

              <Route element={<GroupsPage />} path="/groups" />
              <Route element={<GroupDetailsPage />} path="/groups/:groupId" />
              <Route element={<CreateGroupPage />} path="/groups/new" />

              <Route element={<CommunityPageDetails isAdmin={isAdmin} />} path={`/communities/:communityId`} />

              <Route element={<MembersPage />} path="/members" />
              <Route element={<MemberDetailsPage />} path="/members/:memberId" />

              <Route element={<MessagesPage />} path="/messages" />
              <Route element={<MessageDetailsPage />} path="/messages/new" />
              <Route element={<MessageDetailsPage />} path="/messages/:messageId" />

              <Route element={<MyProfilePage />} path="/profile" />

              <Route
                element={<Navigate replace to={!isAdmin ? `/communities/${community?.id}` : `/communities`} />}
                path="*"
              />
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Route element={<LoginPage />} path="/login" />
          <Route element={<ForgotPasswordPage />} path="/forgot-password" />
          <Route element={<ResetPasswordPage />} path="/forgot-password/reset" />
          <Route element={<RegisterPage />} path="/register" />
          <Route element={<ExpiredTokenPage />} path="/expired" />

          <Route element={<Navigate replace to="/login" />} path="*" />
        </React.Fragment>
      )}
    </Routes>
  );
};
