import classnames from 'classnames';
import React from 'react';

const styles = {
  active: 'bg-green-100 text-green-800 rounded-full',
  basic: 'relative bg-[#EEEEEE] text-black disabled:bg-gray-300 rounded-full',
  bronze: 'bg-[#A5693D]/50 text-gray-600 rounded-full',
  free: 'bg-blue-100 text-blue-800 rounded-full',
  gold: 'bg-[#F1A660]/10 text-[#F1A660] rounded-full',
  group: 'bg-[#EFF4F5] text-[#170E00] text-sm rounded-full',
  inactive: 'bg-neutral-100 text-neutral-800 rounded-full',
  past_due: 'bg-red-100 text-red-800 rounded-full',
  pending: 'bg-[#F1A660]/10 text-[#F1A660] rounded-full',
  silver: 'bg-gray-100 text-gray-800 rounded-full',
  up_to_date: 'bg-green-100 text-green-800 rounded-full'
};

export type Appearance = keyof typeof styles;

type Props = {
  appearance: Appearance;
  className?: string;
};
export const Badge: React.FC<React.PropsWithChildren<Props>> = ({ appearance, children, className }) => {
  return (
    <span className={classnames('px-3 py-0.5 text-xs font-medium', styles[appearance], className)}>{children}</span>
  );
};
