import { translations } from '@/locales';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import { useImageInputChange } from '@/hooks/useImageInputChange';

import { ProgressBar } from '@/components/ProgressBar';

import classnames from 'classnames';
import { Button } from '../Button';
import { Link } from '../Link';
import { FileInput, Props as FileInputProps } from './FileInput';
import { getSourceFromPossibleValue, ImageValue, PossibleValue } from './ImageFileInput';

import removeIcon from '@/assets/images/remove.svg';

export type Props = Omit<FileInputProps, 'onChange' | 'multiple' | 'children'> & {
  value?: PossibleValue | PossibleValue[];
  onChange(file?: ImageValue | File | string): void;
  onRemove?(): void;
  uploadProgress?: number;
  successfullUpload?: boolean;
};

const ImagePreview = ({ src }: { src?: string }) => (
  <img alt="Preview" className="h-[140px] w-64 rounded-md object-cover" src={src} />
);

const FilePreview: React.FC<
  PropsWithChildren<{ fileName: string; className?: string; iconStyle?: string; url?: string }>
> = ({ fileName, className, children, url }) => (
  <div
    className={classnames(
      'flex w-fit flex-col justify-center gap-5 rounded-md  py-2 px-3 text-xs font-normal',
      className
    )}
  >
    <div className="flex items-center gap-2">
      {/* <DocumentTextIcon className={classnames('h-5 w-5', iconStyle)} /> */}

      <Link as="a" className="max-w-2xl truncate" href={url}>
        <span>{url ? 'View PDF attachment ' : fileName}</span>
      </Link>
    </div>

    {children}
  </div>
);

export const getFileTypeFromUrl = (url?: string): 'image' | 'pdf' | 'csv' | 'unknown' => {
  if (!url) return 'unknown';

  const fileExtension = url.slice(url.lastIndexOf('.') + 1);

  switch (fileExtension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
      return 'image';
    case 'pdf':
      return 'pdf';
    case 'csv':
      return 'csv';
    default:
      return 'unknown';
  }
};

export const isURLImage = (url?: string): boolean => {
  return getFileTypeFromUrl(url) === 'image';
};

export const isURLPDF = (url: string): boolean => {
  return getFileTypeFromUrl(url) === 'pdf';
};

export const getFileNameFromUrl = (url: string): string => {
  const lastSlashIndex = url.lastIndexOf('/');
  const fileNameWithParams = url.slice(lastSlashIndex + 1);
  const fileName = fileNameWithParams.split('?')[0];

  return fileName;
};

export const getFileName = (file: string | File): string => {
  return typeof file === 'string' ? getFileNameFromUrl(file) : file.name;
};

export const isFile = (file: PossibleValue): file is File => file instanceof File;

export const checkPredicate = (file: PossibleValue | PossibleValue[], predicate: (file: PossibleValue) => boolean) => {
  if (!Array.isArray(file)) return predicate(file);
  return !file.some((file) => !predicate(file));
};

export const isPDF = (file: PossibleValue | PossibleValue[]): file is File => {
  return checkPredicate(file, (file) => isFile(file) && file.type.includes('pdf'));
};

export const isImage = (file: PossibleValue | PossibleValue[]): file is ImageValue => {
  return checkPredicate(file, (file) => (file as ImageValue).file?.type.includes('image'));
};

export const isCSV = (file: PossibleValue | PossibleValue[]): file is File => {
  return checkPredicate(file, (file) => isFile(file) && file.type.includes('csv'));
};

export const MixedFileInput = React.forwardRef<HTMLInputElement, React.PropsWithChildren<Props>>(
  ({ value, onChange: onChangeCallback, uploadProgress, successfullUpload, ...rest }, ref) => {
    const onImageUpload = useImageInputChange(onChangeCallback);
    /*
    this is a hook that returns the onChange function that
    converts the file to ImageValue
   */

    const onChange = React.useCallback(
      (value: File) => {
        if (isPDF(value) || isCSV(value)) return onChangeCallback(value);
        onImageUpload(value); // process image if the file uploaded is not a pdf or csv
      },
      [onChangeCallback, onImageUpload]
    );

    return (
      <div className="flex w-full items-center justify-between">
        <div className="w-full">
          {' '}
          {/* This div contains the image or file preview */}
          <FileInput {...rest} {...{ ref }} onChange={(files) => onChange(files[0])} />
          {/* value is ImageValue or image url */}
          {!!value && (isImage(value) || (typeof value === 'string' && getFileTypeFromUrl(value) === 'image')) && (
            <ImagePreview src={getSourceFromPossibleValue(value)} />
          )}
          {/* value is PDF/CSV file or PDF/CSV url */}
          {!!value &&
            (isPDF(value) ||
              isCSV(value) ||
              (typeof value === 'string' &&
                (getFileTypeFromUrl(value) === 'pdf' || getFileTypeFromUrl(value) === 'csv'))) && (
              <FilePreview
                className={
                  uploadProgress === 1
                    ? successfullUpload
                      ? 'bg-green-100'
                      : 'bg-red-100 text-red-600'
                    : 'w-full text-base text-[#79747E]'
                }
                fileName={getFileName(value)}
                iconStyle={!successfullUpload && uploadProgress === 1 ? 'text-red-600' : 'text-gray-600'}
                url={!isFile(value) ? value : undefined}
              >
                {uploadProgress !== undefined && uploadProgress !== 1 && (
                  <>
                    <div className="flex justify-between text-xs font-normal text-gray-500">
                      <FormattedMessage
                        id={uploadProgress === 1 ? 'Processed' : translations.pages.members.processing}
                      />

                      <span>{Math.round(uploadProgress * 100)} %</span>
                    </div>

                    <ProgressBar className="w-96" progress={uploadProgress * 100} />
                  </>
                )}
              </FilePreview>
            )}
        </div>

        {!!value && !rest.disabled && (
          <Button appearance="basic" className="border-none shadow-none" onClick={rest.onRemove} type="button">
            {/* <FormattedMessage id={translations.buttons.remove} /> */}
            <img alt="remove" src={removeIcon} />
          </Button>
        )}
      </div>
    );
  }
);
