import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { useRole } from '@/hooks/useRole';
import { translations } from '@/locales/messages';
import { apiClient } from '@/services/api';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import activate from '@/assets/images/activate.svg';
import bronzeCard from '@/assets/images/bronze-card.svg';
import deactivate from '@/assets/images/deactivate.svg';
import freeCard from '@/assets/images/free-card.svg';
import goldCard from '@/assets/images/gold-card.svg';
import silverCard from '@/assets/images/silver-card.svg';

import { AsyncButton } from '@/components/AsyncButton';
import { Appearance, Badge } from '@/components/Badge';
import { CustomInput } from '@/components/CustomInput';
import { LoadingIndicator } from '@/components/Loading';
import { Navbar } from '@/components/Navbar';
import { MemberProfile } from '@/components/Profile';
import { Column, DataTable } from '@/components/Table/DataTable';
import useSWR from 'swr';

export interface PaymentHistory {
  paymentData: string;
  paymentStatus: string;
  plan: string;
  price: number;
}

export const MemberDetailsPage: React.FC = () => {
  const location = useLocation();
  const member = location.state?.member;
  const memberId = member!.id!;
  const { community } = useRole();

  const cardDictionary = {
    bronze: bronzeCard,
    free: freeCard,
    gold: goldCard,
    silver: silverCard
  };

  const paymentStatusDictionary = {
    past_due: 'Past due',
    up_to_date: 'Up to date'
  };

  const questions = [
    {
      options: [
        { label: 'Dubai', selected: false },
        { label: 'London', selected: true },
        { label: 'Other country', selected: false }
      ],
      title: 'Where do you live?',
      type: 'multiple'
    },
    {
      options: [
        { label: 'Dogs', selected: true },
        { label: 'Cats', selected: false },
        { label: 'Foxes', selected: true }
      ],
      title: 'Which animals do you like?',
      type: 'multiple'
    },
    {
      answer: 'Twice a week',
      title: 'How often do you use Univerz App?',
      type: 'single'
    }
  ];

  const data: PaymentHistory[] = [
    {
      paymentData: '2025-11-14T14:39:31.590Z',
      paymentStatus: 'past_due',
      plan: 'gold',
      price: 5000
    },
    {
      paymentData: '2024-11-14T14:39:31.590Z',
      paymentStatus: 'up_to_date',
      plan: 'gold',
      price: 5000
    }
  ];

  const columns: Column<PaymentHistory>[] = [
    {
      accessor: 'paymentData',
      cell: (member: PaymentHistory) => {
        return <span>{new Date(member.paymentData).toISOString().split('T')[0]}</span>;
      },
      header: 'Payment Data'
    },
    {
      accessor: 'price',
      cell: (member: PaymentHistory) => {
        return <span>USD {member.price / 100}</span>;
      },
      header: 'Price'
    },
    {
      accessor: 'payment',
      cell: (member: PaymentHistory) =>
        member.paymentStatus && (
          <Badge appearance={member.paymentStatus as Appearance}>
            <FormattedMessage
              id={translations.paymentStatus[member.paymentStatus as keyof typeof translations.paymentStatus]}
              values={{ active: member.paymentStatus }}
            />
          </Badge>
        ),
      filter: {
        options: [
          { label: 'Past due', value: 'past_due' },
          { label: 'Up to date', value: 'up_to_date' }
        ],
        type: 'multiple'
      },
      header: 'Payment'
    },
    {
      accessor: 'plan',
      cell: (member: PaymentHistory) =>
        member.plan && (
          <Badge appearance={member.plan as Appearance}>
            <FormattedMessage
              id={translations.plans[member.plan as keyof typeof translations.plans]}
              values={{ active: member.plan }}
            />
          </Badge>
        ),
      filter: {
        options: [
          { label: 'Gold', value: 'gold' },
          { label: 'Silver', value: 'silver' },
          { label: 'Bronze', value: 'bronze' },
          { label: 'Free', value: 'free' }
        ],
        type: 'multiple'
      },
      header: 'Plan'
    }
  ];

  const {
    data: memberData,
    isLoading: memberLoading,
    mutate: mutateMember
  } = useSWR(
    memberId,
    () =>
      apiClient.community.getMemberDetails({
        communityId: community!.id,
        memberId
      }),
    {
      keepPreviousData: true
    }
  );

  return (
    <Layout>
      <div className="flex justify-between gap-4 py-10">
        <div className="flex items-center gap-5">
          <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/members">
            <FormattedMessage id={translations.pages.members.members} />
          </Link>

          <ChevronRightIcon className="w-5 text-sm text-gray-400" />

          {!memberLoading && memberData && (
            <span className="text-sm font-medium text-[#ED6F4C]">
              {memberData.firstName} {memberData.lastName}
            </span>
          )}
        </div>

        <div className="flex items-center gap-2">
          <Navbar title="" />
        </div>
      </div>

      <div className=" px-32">
        {memberData && (
          <div className="space-y-4">
            <div className="flex items-center justify-between gap-4">
              <MemberProfile
                birthDate={memberData.birthDate}
                email={memberData.email}
                firstName={memberData.firstName}
                groups={memberData.groups}
                lastName={memberData.lastName}
                plan={memberData.plan}
                shouldShowDetailedProfile={true}
                src={memberData.image}
                status={memberData.status}
              />

              <AsyncButton
                className={classnames('flex items-center gap-2 py-2 px-4 text-sm hover:bg-gray-200', {
                  'text-indigo-400': !memberData.active
                })}
                customLoading={(isLoading) => (
                  <div className="flex items-center gap-2">
                    <img alt="suspend" className="h-7 w-7" src={memberData.active ? deactivate : activate} />
                    {isLoading ? (
                      <LoadingIndicator className="h-4 w-4" />
                    ) : (
                      <FormattedMessage id={translations.buttons.suspend} values={{ active: memberData.active }} />
                    )}
                  </div>
                )}
                onClick={async () => {
                  memberData.active
                    ? await apiClient.community
                        .suspendCommunityMembers({
                          communityId: community!.id,
                          requestBody: { userIds: [memberData.id] }
                        })
                        .then(() => mutateMember())
                    : await apiClient.community
                        .addCommunityMembers({
                          communityId: community!.id,
                          requestBody: { emails: [memberData.email] }
                        })
                        .then(() => mutateMember());
                }}
              ></AsyncButton>
            </div>
            <div className="flex items-center justify-between py-20">
              <div>
                {memberData && <img alt="card" src={cardDictionary[memberData.plan as keyof typeof cardDictionary]} />}
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex justify-between gap-16">
                  <span className="font-semibold text-[#170E00]/60">Date added:</span>
                  <span className="font-normal">2024-01-31</span>
                </div>
                <div className="flex justify-between gap-16">
                  <span className="font-semibold  text-[#170E00]/60 ">Membership number:</span>
                  <span className="font-normal">12345</span>
                </div>
                <div className="flex justify-between gap-16">
                  <span className="font-semibold  text-[#170E00]/60 ">Expiration date:</span>
                  <span className="font-normal">2026-11</span>
                </div>
                <div className="flex justify-between gap-16">
                  <span className="font-semibold  text-[#170E00]/60 ">Annual subscription:</span>
                  <span className="font-normal">USD 50.00</span>
                </div>
                <div className="flex justify-between gap-16">
                  <span className="font-semibold  text-[#170E00]/60 ">Payment Status:</span>
                  <Badge appearance={memberData.paymentStatus as any} className="flex items-center gap-2">
                    {paymentStatusDictionary[memberData.paymentStatus as keyof typeof paymentStatusDictionary]}
                  </Badge>
                </div>
              </div>
            </div>

            <div className="pt-20">
              <div className="flex items-center gap-3 pb-10 text-2xl font-semibold">
                <FormattedMessage id={translations.pages.members.membershipQuestions} />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <CustomInput title="Full name" type="input" value={`${memberData.firstName} ${memberData.lastName}`} />
                <CustomInput title="Phone number with area code" type="input" value={memberData.phone} />
                <CustomInput title="Email" type="input" value={memberData.email} />
                {memberData.birthDate && (
                  <CustomInput
                    title="Birth date"
                    type="input"
                    value={new Date(memberData.birthDate).toISOString().split('T')[0]}
                  />
                )}
                {questions.map((question, index) => (
                  <CustomInput
                    key={index}
                    options={question.options}
                    questionNumber={index + 1}
                    title={question.title}
                    type="question"
                    value={question.answer}
                  />
                ))}
              </div>
            </div>

            <div className="pt-20">
              <div className="flex items-center gap-3 pb-10 text-2xl font-semibold">
                <FormattedMessage id={translations.pages.members.membershipPaymentHistory} />
              </div>

              <DataTable columns={columns} data={data} />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};
