import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';
import * as yup from 'yup';

import { authApiClient } from '@/services/auth-api';

import { translations } from '@/locales';

import { Button } from '@/components/Button';
import { CenteredBoxLayout } from '@/components/CenteredBoxLayout';
import { Form } from '@/components/Form';
import { PasswordInput } from '@/components/Form/PasswordInput';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { LoadingButton } from '@/components/LoadingButton';

import backgroundImg from '@/assets/background-reset-password.png';
import { Modal } from '@/components/Modal';
import { SuccessModal } from '@/components/Modal/SuccessModal';
import { useModal } from '@/hooks/useModal';

export const schema = yup
  .object({
    password: yup.string().trim().required().label(translations.fields.password),
    repeatPassword: yup
      .string()
      .trim()
      .oneOf([yup.ref('password')], translations.customValidation.matchPasswords)
      .required()
      .label(translations.fields.repeatPassword)
  })
  .required();

export const ResetPasswordPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const successModal = useModal(SuccessModal);

  const params = new URLSearchParams(location.search);

  const [token] = [params.get('token'), params.get('email')];

  React.useEffect(() => {
    if (!token) navigate('/expired');
  }, []);

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      await authApiClient.authentication
        .resetPassword({
          requestBody: { password: values.password, token: token! }
        })
        .then(() => successModal.open().catch(() => null));
    },
    [token]
  );

  return (
    <CenteredBoxLayout
      backgroundImg={backgroundImg}
      subhead={<FormattedMessage id={translations.pages.resetPassword.subhead} />}
      title={<FormattedMessage id={translations.pages.resetPassword.title} />}
    >
      <Form {...{ onSubmit, schema }}>
        {({ submitting, submitError }) => (
          <div className="space-y-4">
            <ValidatedField
              field={PasswordInput}
              id="password"
              label={<FormattedMessage id={translations.fields.newPassword} />}
              name="password"
              placeholder="New password"
              readOnly={submitting}
              type="password"
            />

            <ValidatedField
              field={PasswordInput}
              id="repeatPassword"
              label={<FormattedMessage id={translations.fields.repeatPassword} />}
              name="repeatPassword"
              placeholder="Repeat the password"
              readOnly={submitting}
              type="password"
            />

            <Button appearance="primary" as={LoadingButton} className="w-full" loading={submitting} type="submit">
              <FormattedMessage id={translations.pages.resetPassword.cta} />
            </Button>

            {submitError && navigate('/expired')}
          </div>
        )}
      </Form>

      <Modal
        subhead={<FormattedMessage id={translations.pages.resetPassword.modalSubhead} />}
        title={<FormattedMessage id={translations.pages.resetPassword.modalTitle} />}
        {...successModal.props}
        onClose={() => null}
      />
    </CenteredBoxLayout>
  );
};
