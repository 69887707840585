import classnames from 'classnames';
import React from 'react';

import { Button, Props as ButtonProps } from './Button';
import { Loading, LoadingIndicator } from './Loading';

export type Props = ButtonProps & {
  loading: boolean;
  disabledButton?: boolean;
  onClick(event?: React.MouseEvent<HTMLButtonElement>): Promise<void> | void;
};

export const LoadingButton = React.forwardRef(function LoadingButton(
  { loading, children, disabledButton, ...props }: React.PropsWithChildren<Props>,
  ref: React.Ref<any>
) {
  return (
    <Button {...props} {...{ ref }} disabled={loading || disabledButton}>
      <Loading className="absolute inset-0 flex items-center justify-center" visible={loading}>
        <LoadingIndicator className="h-5 w-5" />
      </Loading>

      <div className={classnames('transition-opacity', { 'opacity-0': loading })}>{children}</div>
    </Button>
  );
});
