/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SignUpDto } from '../models/SignUpDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { UploadIntentDto } from '../models/UploadIntentDto';

export class OnboardingService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Creates a new user.
   * @returns void
   * @throws ApiError
   */
  public signUp({
    requestBody,
  }: {
    requestBody: SignUpDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/onboarding/sign-up',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Initiate profile image upload.
   * @returns UploadIntentDto Returns an upload intent.
   * @throws ApiError
   */
  public initiateProfileImageUpload({
    requestBody,
   }: {
    /**
     * Only `image/jpeg` and `image/png` are accepted as mimeType.
     */
    requestBody: {
    mimeType: string;
    },
  }): CancelablePromise<UploadIntentDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/onboarding/profile/image',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
       401: `Credentials are missing or invalid.`,
      },
    });
  }

    /**
   * Delete account.
   * @returns void
   * @throws ApiError
   */
    public deleteAccount({
    }: {
    }): CancelablePromise<void> {
      return this.httpRequest.request({
        method: 'POST',
        url: '/onboarding/delete-account',
        errors: {
          401: `Credentials are missing or invalid.`,
        },
      });
    }

}
