/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum StatusDto {
  ACTIVE = 'active',
  PENDING = 'pending',
  INACTIVE = 'inactive',
}
