/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InitiateResetPasswordPayload } from '../models/InitiateResetPasswordPayload';
import type { LoginWithPasswordPayload } from '../models/LoginWithPasswordPayload';
import type { RefreshTokenPayload } from '../models/RefreshTokenPayload';
import type { ResetPasswordPayload } from '../models/ResetPasswordPayload';
import { updatePasswordPayload } from '../models/updatePasswordPayload';
import type { SessionDto } from '../models/SessionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthenticationService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Authenticates the user.
   * @returns SessionDto Authentication successful.
   * @throws ApiError
   */
  public loginWithPassword({
requestBody,
}: {
requestBody: LoginWithPasswordPayload,
}): CancelablePromise<SessionDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/password/login',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Invalidates the current session.
   * @returns void 
   * @throws ApiError
   */
  public logout({
requestBody,
}: {
requestBody: RefreshTokenPayload,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/logout',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Refreshes the session.
   * @returns SessionDto Session renewed.
   * @throws ApiError
   */
  public renew({
requestBody,
}: {
requestBody: RefreshTokenPayload,
}): CancelablePromise<SessionDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/refresh',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Initiate reset password.
   * @returns any Request has been accepted and it will be processed.
   * @throws ApiError
   */
  public initiateResetPassword({
requestBody,
}: {
requestBody: InitiateResetPasswordPayload,
}): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/password/send',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Reset password.
   * @returns any Request has been accepted and it will be processed.
   * @throws ApiError
   */
  public resetPassword({
requestBody,
}: {
requestBody: ResetPasswordPayload,
}): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/password/reset',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Reset password.
   * @returns any Request has been accepted and it will be processed.
   * @throws ApiError
   */
  public updatePassword({
requestBody,
}: {
requestBody: updatePasswordPayload,
}): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/auth/password/update',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
