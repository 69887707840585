import React from 'react';

import { Sidebar } from './Sidebar';

export const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex">
      <Sidebar />

      <div className="ml-80 flex h-full min-h-screen w-full flex-col space-y-5 overflow-y-auto bg-[#DEDCD3]/10 py-5 px-8">
        {children}
      </div>
    </div>
  );
};
