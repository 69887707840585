import { GroupDto, GroupPreviewDto } from '@billy/management-api-sdk';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Badge } from '@/components/Badge';
import { Button } from '@/components/Button';
import { EmptyList } from '@/components/EmptyList';
import { translations } from '@/locales';
import { apiClient } from '@/services/api';

import { Dropdown } from '../Dropdown';
import { Column, DataTable } from './DataTable';

import deletePath from '@/assets/images/delete.svg';
import edit from '@/assets/images/edit.svg';
import verticalDotsPath from '@/assets/images/vertical-dots.svg';
import { useModal } from '@/hooks/useModal';
import { AddMemberModal } from '@/pages/Groups/AddMemberModal';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Modal } from '../Modal';
import { BasicConfirmationModal } from '../Modal/BasicConfirmationModal';

type Props = {
  groups?: GroupPreviewDto[];
  className?: string;
  empty?: boolean;
  noResults?: boolean;
  onFilter?(field: string, values: (string | number)[]): void;
  onGroupDeleted?(): void;
};

export const GroupsList: React.FC<Props> = ({ groups, empty, onFilter, onGroupDeleted }) => {
  const navigate = useNavigate();
  const [selectedGroup, setSelectedGroup] = useState<GroupDto | null>(null);

  const deleteGroupModal = useModal(BasicConfirmationModal);
  const addMembersModal = useModal(AddMemberModal);

  const columns: Column<GroupPreviewDto>[] = [
    {
      accessor: 'name',
      cell: (group: GroupPreviewDto) => <Badge appearance="basic">{group.name}</Badge>,
      header: 'Group',
      width: '40%'
    },
    {
      accessor: 'memberCount',
      cell: (group: GroupPreviewDto) => (
        <div className="flex items-center gap-2">
          <span>{group.memberCount}</span>
        </div>
      ),
      header: 'Members',
      width: '40%'
    },
    {
      accessor: 'actions',
      cell: (group: GroupDto) => (
        <Button
          appearance="basicSecondary"
          as={RouterLink}
          className="flex w-min items-center justify-center gap-2"
          onClick={() => {
            group.id && addMembersModal.open({ groupId: group.id }).catch(() => null);
          }}
        >
          <PlusIcon className="h-5 w-5" />
          <FormattedMessage id={translations.buttons.addMembers} />
        </Button>
      ),
      header: '',
      width: '10%'
    },
    {
      accessor: 'actions',
      cell: (group: GroupDto) => (
        <Dropdown
          appearance="filter"
          dropdownClassName="absolute mt-2 w-48 rounded-md bg-white shadow-lg z-10 border border-gray-200 right-0 top-full"
          shouldUseShadow={false}
          svgPath={verticalDotsPath}
          trigger={
            <Button appearance="filter" className="rounded-full p-2 hover:bg-gray-100" shouldUseShadow={false}>
              <img alt="vertical dots" className="h-5 w-5" src={verticalDotsPath} />
            </Button>
          }
        >
          <Button
            className="flex w-full items-center gap-2 py-2 px-4 text-sm hover:bg-gray-200"
            onClick={() => {
              navigate(`/groups/${group.id}`);
            }}
          >
            <div className="flex items-center gap-2">
              <img alt="edit" className="h-7 w-7" src={edit} />
              <FormattedMessage id={translations.buttons.edit} />
            </div>
          </Button>

          <Button
            className="flex w-full items-center gap-2 py-2 px-4 text-sm hover:bg-gray-200"
            onClick={() => {
              setSelectedGroup(group);
              deleteGroupModal.open().catch(() => null);
            }}
          >
            <div className="flex items-center gap-2">
              <img alt="delete" className="h-7 w-7" src={deletePath} />
              <FormattedMessage id={translations.buttons.delete} />
            </div>
          </Button>
        </Dropdown>
      ),
      header: '',
      width: '10%'
    }
  ];

  return (
    <>
      {empty ? (
        <EmptyList
          description={<FormattedMessage id={translations.emptyStates.createGroup} />}
          title={<FormattedMessage id={translations.emptyStates.noGroups} />}
        >
          <Button appearance="primary" as={RouterLink} className="flex w-48 justify-center" to="/groups/new">
            <FormattedMessage id={translations.buttons.createGroup} />
          </Button>
        </EmptyList>
      ) : (
        <DataTable
          columns={columns}
          data={groups || []}
          onFilter={onFilter}
          // onRowClick={(group) => {
          //   window.location.href = `/groups/${group.id}`;
          // }}
        />
      )}

      {selectedGroup && (
        <Modal
          buttonMessage={<FormattedMessage id={translations.buttons.delete} />}
          {...deleteGroupModal.props}
          onConfirmation={async () => {
            await apiClient.group.deleteGroup({ groupId: selectedGroup.id! });
            onGroupDeleted?.();
          }}
          subhead={<FormattedMessage id={translations.pages.groups.deleteGroupDescription} />}
          title={
            <FormattedMessage id={translations.pages.groups.deleteGroupTitle} values={{ name: selectedGroup.name }} />
          }
        />
      )}

      <Modal
        onInvite={async (groupIds, membersIds) => {
          await apiClient.group.addGroupMembers({
            groupId: groupIds[0],
            requestBody: { groupIds, userIds: membersIds }
          });
          onGroupDeleted?.();
        }}
        {...addMembersModal.props}
      />
    </>
  );
};
