import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import classnames from 'classnames';
import React from 'react';

import { AsComponent } from '@/components/As';
import { Button } from '@/components/Button';

import { Input, Props } from './Input';

export const PasswordInput = React.forwardRef(function PasswordInput<T extends AsComponent>(
  props: Props<T>,
  ref: React.Ref<any>
) {
  const [showPassword, setShowPassword] = React.useState(false);

  const toggleShowPassword = React.useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const Icon = showPassword ? EyeIcon : EyeSlashIcon;

  return (
    <label className={classnames('relative', props.className)} htmlFor={props.id}>
      <Input {...props} {...{ ref }} className="pr-12" type={showPassword ? 'text' : 'password'} />

      <Button className="absolute inset-y-0 right-0 flex items-center" onClick={toggleShowPassword} type="button">
        <Icon className="h-5 w-5 text-gray-400" />
      </Button>
    </label>
  );
});
