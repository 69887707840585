import classnames from 'classnames';
import React from 'react';

import { As, AsComponent, AsProps } from '@/components/As';

export type Props<T extends AsComponent> = AsProps<T> & {
  invalid?: boolean;
};

export const getInputReadOnlyClassNames = (readOnly?: boolean) => classnames(readOnly && 'bg-gray-100 text-gray-400');
export const getInputInvalidClassNames = (invalid?: boolean) =>
  classnames(invalid ? 'border-red-500' : 'border-[#003F2E4D]');

export const Input = React.forwardRef(function Input<T extends AsComponent = 'input'>(
  // @ts-ignore
  { as = 'input', invalid, ...props }: React.PropsWithChildren<Props<T>>,
  ref: React.Ref<any>
) {
  return (
    // @ts-ignore
    <As
      {...props}
      {...{ as, ref }}
      className={classnames(
        'w-full rounded-xl border border-gray-300 py-2 px-2 text-gray-900 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm',
        getInputInvalidClassNames(props.invalid),
        getInputReadOnlyClassNames(props.readOnly || props.disabled),
        props.className
      )}
    />
  );
});
