import classnames from 'classnames';
import React from 'react';

import activeLastLeftNavigation from '@/assets/images/active-last-left-navigation.svg';
import activeLastRightNavigation from '@/assets/images/active-last-right-navigation.svg';
import activeNextLeftNavigation from '@/assets/images/active-next-left-navigation.svg';
import activeNextRightNavigation from '@/assets/images/active-next-right-navigation.svg';
import inactiveLastLeftNavigation from '@/assets/images/inactive-last-left-navigation.svg';
import inactiveLastRightNavigation from '@/assets/images/inactive-last-right-navigation.svg';
import inactiveNextLeftNavigation from '@/assets/images/inactive-next-left-navigation.svg';
import inactiveNextRightNavigation from '@/assets/images/inactive-next-right-navigation.svg';

import { Button } from './Button';

export interface Props {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  className?: string;
  onPageChange(page: number): void;
}

type PageButtonProps = {
  onClick?: (page: number) => void;
  className?: string;
  disabled?: boolean;
  shouldUseShadow?: boolean;
};

const paginationButtonStyle =
  'flex h-10 w-10 items-center justify-center rounded-none border text-sm text-gray-500 hover:bg-gray-100';

const newPaginationButtonStyle =
  'flex h-10 w-10 items-center justify-center rounded-none text-gray-400 border-none hover:bg-transparent';
const activePageStyle =
  'flex h-10 w-10 items-center justify-center border-none hover:bg-transparent disabled:bg-gray-100 disabled:font-bold disabled:rounded-xl disabled:text-black';

const PageButton: React.FC<React.PropsWithChildren<PageButtonProps>> = ({
  children,
  className,
  disabled,
  onClick,
  shouldUseShadow
}) => {
  return (
    <Button
      className={classnames(paginationButtonStyle, className)}
      {...{ disabled, onClick }}
      shouldUseShadow={shouldUseShadow}
    >
      {children}
    </Button>
  );
};

export const Pagination: React.FC<Props> = ({ totalItems, pageSize, currentPage, onPageChange, className }) => {
  const totalPages = Math.ceil(totalItems / pageSize) || 1;
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <div className={classnames('flex items-center justify-between', className)}>
      <div></div>
      <div className="flex items-center">
        <PageButton
          className={classnames(newPaginationButtonStyle, 'w-fit')}
          disabled={isFirstPage}
          onClick={() => onPageChange(currentPage - 1)}
          shouldUseShadow={false}
        >
          <img alt="Next Left Navigation" src={isFirstPage ? inactiveNextLeftNavigation : activeNextLeftNavigation} />
        </PageButton>

        <PageButton
          className={classnames(newPaginationButtonStyle, 'w-fit')}
          disabled={isFirstPage}
          onClick={() => onPageChange(1)}
          shouldUseShadow={false}
        >
          <img alt="Last Left Navigation" src={isFirstPage ? inactiveLastLeftNavigation : activeLastLeftNavigation} />
        </PageButton>

        {totalPages <= 6 ? (
          pageNumbers.map((pageNumber) => (
            <PageButton
              className={classnames(newPaginationButtonStyle, activePageStyle, 'w-fit')}
              disabled={pageNumber === currentPage}
              key={pageNumber}
              onClick={() => onPageChange(pageNumber)}
              shouldUseShadow={false}
            >
              {pageNumber}
            </PageButton>
          ))
        ) : (
          <>
            {currentPage <= (totalPages % 2 === 0 ? totalPages / 2 : Math.floor(totalPages / 2)) ? (
              <>
                {Array.from({ length: 3 }, (_, i) => currentPage + i).map((pageNumber) => (
                  <PageButton
                    className={classnames(newPaginationButtonStyle, activePageStyle, 'w-fit')}
                    disabled={pageNumber === currentPage}
                    key={pageNumber}
                    onClick={() => onPageChange(pageNumber)}
                    shouldUseShadow={false}
                  >
                    {pageNumber}
                  </PageButton>
                ))}
                <span className="px-2">...</span>
                <PageButton
                  className={classnames(newPaginationButtonStyle, activePageStyle, 'w-fit')}
                  disabled={totalPages === currentPage}
                  onClick={() => onPageChange(totalPages)}
                  shouldUseShadow={false}
                >
                  {totalPages}
                </PageButton>
              </>
            ) : (
              <>
                <PageButton
                  className={classnames(newPaginationButtonStyle, activePageStyle, 'w-fit')}
                  disabled={1 === currentPage}
                  onClick={() => onPageChange(1)}
                  shouldUseShadow={false}
                >
                  1
                </PageButton>
                <span className="px-2">...</span>
                {Array.from({ length: 3 }, (_, i) => currentPage + i).map((pageNumber) => (
                  <PageButton
                    className={classnames(newPaginationButtonStyle, activePageStyle, 'w-fit')}
                    disabled={pageNumber === currentPage}
                    key={pageNumber}
                    onClick={() => onPageChange(pageNumber)}
                    shouldUseShadow={false}
                  >
                    {pageNumber}
                  </PageButton>
                ))}
              </>
            )}
          </>
        )}

        <PageButton
          className={classnames(newPaginationButtonStyle, 'w-fit')}
          disabled={isLastPage}
          onClick={() => onPageChange(totalPages)}
          shouldUseShadow={false}
        >
          <img alt="Last Right Navigation" src={isLastPage ? inactiveLastRightNavigation : activeLastRightNavigation} />
        </PageButton>

        <PageButton
          className={classnames(newPaginationButtonStyle, 'w-fit')}
          disabled={isLastPage}
          onClick={() => onPageChange(currentPage + 1)}
          shouldUseShadow={false}
        >
          <img alt="Next Right Navigation" src={isLastPage ? inactiveNextRightNavigation : activeNextRightNavigation} />
        </PageButton>
      </div>
    </div>
  );
};
