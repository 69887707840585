/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateEventDto } from '../models/CreateEventDto';
import type { EventDto } from '../models/EventDto';
import type { EventMemberPageDto } from '../models/EventMemberPageDto';
import type { EventPageDto } from '../models/EventPageDto';
import type { EventTimeRange } from '../models/EventTimeRange';
import type { UploadIntentDto } from '../models/UploadIntentDto';
import type { UploadRequestBody } from '../models/UploadRequestBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Return a page of events.
   * @returns EventPageDto Returns a page of events.
   * @throws ApiError
   */
  public findEvents({
communityId,
time,
searchQuery,
pageSize,
pageNumber,
}: {
/**
 * The id of the community.
 */
communityId?: string,
/**
 * Time to filter by.
 */
time?: EventTimeRange,
/**
 * text to search by.
 */
searchQuery?: string,
pageSize?: number,
pageNumber?: number,
}): CancelablePromise<EventPageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/events/find',
      query: {
        'communityId': communityId,
        'time': time,
        'searchQuery': searchQuery,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Create a event.
   * @returns EventDto Returns the created event.
   * @throws ApiError
   */
  public createEvent({
communityId,
requestBody,
}: {
/**
 * The id of the community.
 */
communityId: string,
requestBody: CreateEventDto,
}): CancelablePromise<EventDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/events/create',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Initiate event image upload.
   * @returns UploadIntentDto Returns an upload intent.
   * @throws ApiError
   */
  public initiateEventImageUpload({
communityId,
requestBody,
}: {
/**
 * The id of the community.
 */
communityId: string,
/**
 * Only `image/jpeg`, `image/png` are accepted as mimeType.
 */
requestBody: UploadRequestBody,
}): CancelablePromise<UploadIntentDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/events/initiate-image-upload',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        403: `The action is not allowed.`,
      },
    });
  }

  /**
   * Return event details.
   * @returns EventDto Returns the event.
   * @throws ApiError
   */
  public getEventDetails({
eventId,
}: {
/**
 * The id of the event.
 */
eventId: string,
}): CancelablePromise<EventDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/events/get-details',
      query: {
        'eventId': eventId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Delete a event.
   * @returns void 
   * @throws ApiError
   */
  public deleteEvent({
eventId,
}: {
/**
 * The id of the event.
 */
eventId: string,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/events/delete',
      query: {
        'eventId': eventId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Cancel an event.
   * @returns void 
   * @throws ApiError
   */
  public cancelEvent({
eventId,
}: {
/**
 * The id of the event.
 */
eventId: string,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/events/cancel',
      query: {
        'eventId': eventId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Return the members of this audience.
   * @returns EventMemberPageDto Returns a page of members part of this audience.
   * @throws ApiError
   */
  public findEventAudienceMembers({
eventId,
pageSize,
pageNumber,
}: {
/**
 * The id of the event.
 */
eventId: string,
pageSize?: number,
pageNumber?: number,
}): CancelablePromise<EventMemberPageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/events/find-audience-members',
      query: {
        'eventId': eventId,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

}
