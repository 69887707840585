import activate from '@/assets/images/activate.svg';
import deactivate from '@/assets/images/deactivate.svg';
import verticalDotsPath from '@/assets/images/vertical-dots.svg';
import view_details from '@/assets/images/view-details.svg';

import { MemberDto } from '@billy/management-api-sdk';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { translations } from '@/locales';

import { apiClient } from '@/services/api';

import { useRole } from '@/hooks/useRole';

import { AsyncButton } from '@/components/AsyncButton';
import { Appearance, Badge } from '@/components/Badge';
import { Button } from '@/components/Button';
import { Dropdown } from '@/components/Dropdown';
import { EmptyList } from '@/components/EmptyList';
import { MemberProfile } from '@/components/Profile';

import { LoadingIndicator } from '../Loading';
import { Column, DataTable, TableAction } from './DataTable';

export type Member = {
  id: string;
  firstName: string;
  lastName: string;
  image: string;
  email: string;
  phone: string;
  createdAt: Date;
  active: boolean;
};

type Props = {
  members?: MemberDto[];
  className?: string;
  empty?: boolean;
  noResults?: boolean;
  onAction?(): void;
  communityId?: string;
  onReload?(): void;
  onFilter?(field: string, values: (string | number)[]): void;
};

export const MembersList: React.FC<Props> = ({
  members,
  empty,
  // noResults,
  onAction,
  communityId,
  onReload,
  onFilter
  // className
}) => {
  const { isAdmin } = useRole();
  const navigate = useNavigate();
  const handleFilter = (field: string, values: (string | number)[]) => {
    onFilter?.(field, values);
  };

  const actions: TableAction<MemberDto>[] = [
    {
      appearance: 'basicSecondary',
      label: 'Assign groups',
      onClick: () => {
        //TODO: Implementar método
        console.log('Assign groups');
      }
    },
    {
      appearance: 'deleteTertiary',
      label: 'Remove members',
      onClick: () => {
        //TODO: Implementar método
        console.log('Remove members');
      }
    }
  ];

  const columns: Column<MemberDto>[] = [
    {
      accessor: 'name',
      cell: (member: MemberDto) => (
        <MemberProfile
          email={member.email}
          firstName={member.firstName}
          lastName={member.lastName}
          src={member.image}
        />
      ),
      header: 'Name'
    },
    {
      accessor: 'status',
      cell: (member: MemberDto) => (
        <Badge appearance={member.active ? 'active' : 'inactive'}>
          <FormattedMessage id={translations.statuses.active} values={{ active: member.active }} />
        </Badge>
      ),
      filter: {
        options: [
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
          { label: 'Pending', value: 'pending' }
        ],
        type: 'multiple'
      },
      header: 'Status'
    },
    {
      accessor: 'groups',
      // TODO: Implementar no back ordenação
      // filter: {
      //   type: 'single',
      //   options: []
      // },
      cell: (member: MemberDto) => {
        return (
          <div className="text-[#ED6F4C]">
            {(member.groupsCount ?? 0) === 0 && '0 groups'}
            {(member.groupsCount ?? 0) === 1 && '1 group'}
            {(member.groupsCount ?? 0) > 1 && `${member.groupsCount} groups`}
          </div>
        );
      },

      header: 'Groups'
    },
    {
      accessor: 'payment',
      cell: (member: MemberDto) =>
        member.paymentStatus && (
          <Badge appearance={member.paymentStatus as Appearance}>
            <FormattedMessage
              id={translations.paymentStatus[member.paymentStatus as keyof typeof translations.paymentStatus]}
              values={{ active: member.paymentStatus }}
            />
          </Badge>
        ),
      filter: {
        options: [
          { label: 'Past due', value: 'past_due' },
          { label: 'Up to date', value: 'up_to_date' }
        ],
        type: 'multiple'
      },
      header: 'Payment'
    },
    {
      accessor: 'plan',
      cell: (member: MemberDto) =>
        member.plan && (
          <Badge appearance={member.plan as Appearance}>
            <FormattedMessage
              id={translations.plans[member.plan as keyof typeof translations.plans]}
              values={{ active: member.plan }}
            />
          </Badge>
        ),
      filter: {
        options: [
          { label: 'Gold', value: 'gold' },
          { label: 'Silver', value: 'silver' },
          { label: 'Bronze', value: 'bronze' },
          { label: 'Free', value: 'free' }
        ],
        type: 'multiple'
      },
      header: 'Plan'
    },
    {
      accessor: 'actions',
      cell: (member: MemberDto) => (
        <Dropdown
          appearance="filter"
          dropdownClassName="absolute mt-2 w-48 rounded-md bg-white z-10 right-0 top-full"
          shouldUseShadow={false}
          svgPath={verticalDotsPath}
          trigger={
            <Button appearance="filter" className="rounded-full p-2 " shouldUseShadow={false}>
              <img alt="vertical dots" className="h-5 w-5" src={verticalDotsPath} />
            </Button>
          }
        >
          <Button
            className="flex w-full items-center gap-2 py-2 px-4 text-sm hover:bg-gray-200"
            onClick={() => {
              navigate(`/members/${member.id}`, { state: { member } });
            }}
          >
            <div className="flex items-center gap-2">
              <img alt="view details" className="h-7 w-7" src={view_details} />
              <FormattedMessage id={translations.buttons.viewDetails} />
            </div>
          </Button>

          {/* <Button
            className="flex w-full items-center gap-2 py-2 px-4 text-sm hover:bg-gray-200"
            onClick={() => {
              console.log('Editing');
            }}
          >
            <div className="flex items-center gap-2">
              <img alt="edit" className="h-7 w-7" src={edit} />
              <FormattedMessage id={translations.buttons.edit} />
            </div>
          </Button> */}

          <AsyncButton
            className={classnames('flex w-full items-center gap-2 py-2 px-4 text-sm hover:bg-gray-200', {
              'text-indigo-400': !member.active
            })}
            customLoading={(isLoading) => (
              <div className="flex items-center gap-2">
                <img alt="suspend" className="h-7 w-7" src={member.active ? deactivate : activate} />
                {isLoading ? (
                  <LoadingIndicator className="h-4 w-4" />
                ) : (
                  <FormattedMessage id={translations.buttons.suspend} values={{ active: member.active }} />
                )}
              </div>
            )}
            onClick={async () => {
              member.active
                ? await apiClient.community
                    .suspendCommunityMembers({
                      communityId: communityId!,
                      requestBody: { userIds: [member.id] }
                    })
                    .then(() => !!onReload && onReload())
                : await apiClient.community
                    .addCommunityMembers({
                      communityId: communityId!,
                      requestBody: { emails: [member.email] }
                    })
                    .then(() => !!onReload && onReload());
            }}
          ></AsyncButton>
        </Dropdown>
      ),
      header: ''
    }
  ];

  return empty ? (
    <EmptyList
      description={!isAdmin && <FormattedMessage id={translations.emptyStates.addMembers} />}
      title={<FormattedMessage id={translations.emptyStates.noMembers} />}
    >
      {!isAdmin && (
        <Dropdown
          buttonText={
            <span className="flex gap-3">
              <FormattedMessage id={translations.buttons.addMembers} />

              <ChevronDownIcon className="h-5 w-5 stroke-2" />
            </span>
          }
        >
          <Button className="w-full rounded-b-none py-2 px-4 text-sm hover:bg-gray-200" onClick={onAction}>
            <FormattedMessage id={translations.pages.members.addAMember} />
          </Button>

          <Button
            as={RouterLink}
            className="flex justify-center rounded-md rounded-t-none text-sm hover:bg-gray-200"
            to="/import-members"
          >
            <FormattedMessage id={translations.pages.members.importMembers} />
          </Button>
        </Dropdown>
      )}
    </EmptyList>
  ) : (
    <DataTable
      actions={actions}
      columns={columns}
      data={members || []}
      onFilter={handleFilter}
      onSelectionChange={(selectedItems) => {
        //TODO: Implementar seleção de membros
        console.log('selectedItems', selectedItems);
      }}
      selectable={false}
    />
  );
};
