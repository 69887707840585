import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link } from '@/components/Link';

import logoFooter from '@/assets/logo-footer.png';
import logo from '@/assets/univerz-icon.png';

export type Props = {
  title: React.ReactNode;
  subhead?: React.ReactNode;
  goBack?: React.ReactNode;
  backgroundImg: string;
};

export const CenteredBoxLayout = ({
  title,
  children,
  subhead,
  goBack,
  backgroundImg
}: React.PropsWithChildren<Props>) => (
  <div className="flex h-screen items-center justify-between bg-gray-50 p-3">
    <div className="mx-auto flex h-full flex-col justify-between py-10">
      <div className="h-8 w-72">
        <img alt="Univerz logo" className="h-full object-contain" src={logo} />
      </div>

      <div className="w-80">
        <div className="mt-16 flex flex-col text-left text-lg font-extrabold text-gray-900">
          <div className="flex items-center gap-3">
            {!!goBack && (
              <Link as={RouterLink} className="text-indigo-600" to="/login">
                {goBack}
              </Link>
            )}
            <h2>{title}</h2>
          </div>
        </div>
        {!!subhead && <div className="mt-1 text-xs font-normal text-gray-900">{subhead}</div>}

        <div className="w-full max-w-md py-8">{children}</div>
      </div>

      <div className="h-8 w-52">
        <img alt="Univerz footer logo" className="h-full object-contain" src={logoFooter} />
      </div>
    </div>

    <div className="max-display:block hidden h-full overflow-hidden rounded-lg object-cover">
      <img alt="background image" className="h-full w-full rounded-lg object-contain" src={backgroundImg} />
    </div>
  </div>
);
