import React from 'react';
import * as yup from 'yup';

import { TrashIcon } from '@heroicons/react/24/solid';

import { Button } from '@/components/Button';
import { MemberDto } from '@billy/management-api-sdk';

import classNames from 'classnames';
import { InstanceProps } from './Modal';
import { ModalLayout } from './ModalLayout';

import backgroundImg from '@/assets/Vector.png';
import { translations } from '@/locales';
import { extractErrorMessage } from '@/utils/functions';
import { FormattedMessage, useIntl } from 'react-intl';
import { AsyncButton } from '../AsyncButton';
import { FeedbackMessage } from '../FeedbackMessage';
import { Form } from '../Form';
import { PasswordInput } from '../Form/PasswordInput';
import { ValidatedField } from '../Form/ValidatedField';

interface ModalProps extends InstanceProps<undefined, Pick<MemberDto, 'email' | 'firstName' | 'id'>> {
  title: React.ReactNode;
  subhead: React.ReactNode;
  isRequiredPass?: React.ReactNode;
  buttonMessage: React.ReactNode;
  onConfirmation(resourceId?: string): Promise<void>;
}

export const schema = yup
  .object({
    password: yup.string().trim().required().label(translations.fields.password)
  })
  .required();

export const BasicConfirmationModal: React.FC<ModalProps> = ({
  title,
  subhead,
  isRequiredPass = false,
  buttonMessage,
  onClose,
  className,
  onConfirmation,
  data
}) => {
  const [error, setError] = React.useState<Error | undefined>();
  const intl = useIntl();

  const onSubmit = React.useCallback(async (values: yup.InferType<typeof schema> | any) => {
    if (isRequiredPass) {
      try {
        await schema.validate(values);
        console.log(values);
      } catch (error) {
        console.error('Validation error:', error);
      }
    } else {
      console.log(values);
    }
  }, []);

  return (
    <ModalLayout className={classNames(className, 'max-w-modal py-0 px-0')}>
      <div className="flex flex-col items-center gap-0">
        <div
          className="flex w-full flex-col gap-4 bg-contain bg-no-repeat p-4"
          style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundPosition: 'right bottom -55px',
            backgroundSize: '60% 130%'
          }}
        >
          <TrashIcon className="h-12 w-12 rounded-full bg-[#E7625E] p-3 text-white" />

          <div>
            <span className="text-xl font-bold capitalize">{title}</span>

            <p className="mt-2 text-xs font-normal">{subhead}</p>
            {isRequiredPass && <p className="mt-4 text-xs font-normal">To proceed, type your password</p>}
          </div>
          {!!error && (
            <FeedbackMessage className="mt-4">
              {extractErrorMessage(error, intl.formatMessage({ id: translations.errors.errorOccurred }))}
            </FeedbackMessage>
          )}
        </div>

        {isRequiredPass ? (
          <Form {...{ className: 'w-full', onSubmit, schema }}>
            {({ submitting }) => (
              <div className="flex w-full flex-col space-y-3.5">
                <div className="px-4">
                  <ValidatedField
                    field={PasswordInput}
                    id="password"
                    label={<FormattedMessage id={translations.fields.newPassword} />}
                    name="password"
                    placeholder="Actual password"
                    readOnly={submitting}
                    type="password"
                  />
                </div>
                <div className=" flex w-full gap-4 rounded-b-lg bg-[#F9F9F9] p-4">
                  <Button
                    appearance="basicSecondary"
                    className="w-full hover:bg-gray-300"
                    onClick={onClose}
                    type="button"
                  >
                    <FormattedMessage id={translations.buttons.cancel} />
                  </Button>

                  <Button
                    appearance="deleteTertiary"
                    className="flex w-full items-center justify-center gap-1 border-[#DE3530] bg-transparent hover:border-transparent hover:bg-[rgba(231,98,94,0.24)]"
                    type="submit"
                  >
                    {buttonMessage}
                  </Button>
                </div>
              </div>
            )}
          </Form>
        ) : (
          <div className=" flex w-full gap-4 rounded-b-lg bg-[#F9F9F9] p-4">
            <Button appearance="basicSecondary" className="w-full hover:bg-gray-300" onClick={onClose} type="button">
              <FormattedMessage id={translations.buttons.cancel} />
            </Button>

            <AsyncButton
              appearance="deleteTertiary"
              className="flex w-full items-center justify-center gap-1 border-[#DE3530] bg-transparent hover:border-transparent hover:bg-[rgba(231,98,94,0.24)]"
              onClick={async () => {
                try {
                  await onConfirmation(data?.id);
                  onClose();
                } catch (err) {
                  setError(err as Error);
                }
              }}
            >
              {buttonMessage}
            </AsyncButton>
          </div>
        )}
      </div>
    </ModalLayout>
  );
};
