import success from '@/assets/images/success.svg';
import React from 'react';

interface InputProps {
  title: string;
  type: 'question' | 'input';
  value?: string;
  options?: { label: string; selected: boolean }[];
  onChange?: (value: string) => void;
  questionNumber?: number;
}

export const CustomInput: React.FC<InputProps> = ({ title, value, options, type, questionNumber }) => {
  return (
    <div className="mb-4 flex flex-col">
      {type === 'question' ? (
        <div className="flex flex-col rounded-xl bg-white p-4">
          {options && options.length ? (
            <>
              <span className="text-md font-medium text-[#170E00]">
                {questionNumber}. {title}
              </span>
              {options.map((option, index) => (
                <label
                  className={`my-2 flex items-center rounded-xl border-2 py-4 px-4 ${
                    option.selected ? 'border-[#48D9D4]' : ''
                  }`}
                  key={index}
                >
                  <span className="pr-2">{option.label}</span>
                  {option.selected && <img alt="success" className="h-4 w-4" src={success} />}
                </label>
              ))}
            </>
          ) : (
            <>
              <span className="text-md font-medium text-[#170E00]">
                {questionNumber}. {title}
              </span>
              <span className="text-md pt-4 font-normal">{value}</span>
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-col rounded-xl bg-white p-4">
          <span className="font-[#170E00]/50 text-sm text-gray-500">{title}</span>
          <span className="text-md font-normal">{value}</span>
        </div>
      )}
    </div>
  );
};
