import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { Link } from '@/components/Link';

import errorIcon from '@/assets/error-icon.png';
import logo from '@/assets/univerz-icon.png';
import backgroundImg from '@/assets/Vector.png';
import { Button } from '@/components/Button';

export const ExpiredTokenPage: React.FC = () => {
  return (
    <div className="relative h-screen w-full overflow-hidden">
      <div className="transformw-1/2 absolute -left-48 -top-44 h-3/4 w-2/4 rotate-180">
        <img className="h-full w-full object-cover" src={backgroundImg} />
      </div>

      <div className="flex h-screen flex-col items-center">
        <div className="mt-16 flex h-8 w-72 items-center justify-center">
          <img alt="Univerz logo" className="h-full object-contain" src={logo} />
        </div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="flex w-11/12 max-w-xs flex-col items-center justify-center gap-2">
            <div className="h-8 w-8">
              <img alt="Success Icon" className="h-full object-contain" src={errorIcon} />
            </div>
            <div className="flex flex-col items-center">
              <span className="text-xl font-bold capitalize">Link expired</span>
              <p className="mt-1 mb-2 text-center text-xs font-normal text-gray-500">
                The link used is either invalid or has expired.
              </p>
            </div>
            <Button appearance="primary" className="w-full" type="button">
              <Link as={RouterLink} to="/login">
                Log in
              </Link>
            </Button>
          </div>
        </div>
      </div>

      <div className="absolute -bottom-48 -right-20 w-2/4">
        <img className="h-full w-full object-cover" src={backgroundImg} />
      </div>
    </div>
  );
};
