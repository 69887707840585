import { MemberDto, MemberPageDto, UserPageDto } from '@billy/management-api-sdk';
import { ChevronRightIcon, PlusIcon } from '@heroicons/react/24/outline';

import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { apiClient } from '@/services/api';

import { translations } from '@/locales';

import { useModal } from '@/hooks/useModal';
import { page, paymentStatuses, plans, search, size, status, statuses, useQueryParams } from '@/hooks/useQueryParams';
import { useRole } from '@/hooks/useRole';

import { Button } from '@/components/Button';
import { Dropdown } from '@/components/Dropdown';
import { DebouncedInput } from '@/components/Form/DebouncedInput';
import { SearchInput } from '@/components/Form/SearchInput';
import { Layout } from '@/components/Layout';
import { LoadingOverlay } from '@/components/Loading';
import { Modal } from '@/components/Modal';
import { InvitationModal } from '@/components/Modal/InvitationModal';
import { Pagination } from '@/components/Pagination';
import { AdminMembersList } from '@/components/Table/AdminMembersList';
import { GroupsList } from '@/components/Table/GroupsList';
import { MembersList } from '@/components/Table/MembersList';
import { emailSchema } from '@/pages/CommunityDetailPage';

import { Navbar } from '@/components/Navbar';
import { CreateGroupModal } from '../Groups/CreateGroupModal';

const config = { page, paymentStatuses, plans, search, size, status, statuses };

export const MembersPage: React.FC = () => {
  const navigate = useNavigate();
  const { community, isAdmin } = useRole();

  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const communityId = params.get('community');
  const initialTab = params.get('tab') === 'groups' ? 'groups' : 'members';

  const [queryParams, updateQueryParams] = useQueryParams({ config });

  const [activeTab, setActiveTab] = useState<'members' | 'groups'>(initialTab);
  const [membersSearch, setMembersSearch] = useState('');
  const [groupsSearch, setGroupsSearch] = useState('');

  const membersKey = `communities/find-members?page=${queryParams.page}&size=${queryParams.size}&status=${queryParams.status}&search=${membersSearch}&paymentStatuses=${queryParams.paymentStatuses}&plans=${queryParams.plans}&statuses=${queryParams.statuses}`;

  const groupsKey = `groups/find?page=${queryParams.page}&size=${queryParams.size}&search=${groupsSearch}`;

  const {
    isLoading: membersLoading,
    error: membersError,
    data: membersData,
    mutate: onReload
  } = useSWR(
    membersKey,
    async () => {
      let result: MemberPageDto | UserPageDto;

      if (isAdmin) {
        if (communityId) {
          result = await apiClient.community.findCommunityMembers({
            communityId,
            pageNumber: queryParams.page,
            pageSize: queryParams.size,
            searchQuery: queryParams.search,
            status: queryParams.status
          });
        } else {
          result = await apiClient.user.findUsers({
            pageNumber: queryParams.page,
            pageSize: queryParams.size,
            searchQuery: queryParams.search,
            status: queryParams.status
          });
        }
      } else {
        result = await apiClient.community.findCommunityMembers({
          communityId: community!.id,
          pageNumber: queryParams.page,
          pageSize: queryParams.size,
          paymentStatuses: queryParams.paymentStatuses,
          plans: queryParams.plans,
          searchQuery: queryParams.search || membersSearch,
          status: queryParams.status,
          statuses: queryParams.statuses
        });
      }

      return result;
    },
    {
      keepPreviousData: true,
      revalidateOnFocus: false
    }
  );

  const {
    data: groupsData,
    isLoading: groupsLoading,
    error: groupsError
  } = useSWR(
    groupsKey,
    () =>
      apiClient.group.findGroups({
        communityId: isAdmin ? undefined : community?.id,
        pageNumber: queryParams.page,
        pageSize: queryParams.size,
        searchQuery: queryParams.search
      }),
    {
      keepPreviousData: true
    }
  );

  const intl = useIntl();

  const isFilterActive = !!queryParams.status || !!queryParams.search;

  const hasMembers = membersData?.page.total !== 0 || isFilterActive;

  const noResultsFound = isFilterActive && membersData?.page.total === 0;

  const invitationModal = useModal(InvitationModal);

  const createGroupModal = useModal(CreateGroupModal);

  const filterFieldMap: Record<string, string> = {
    paymentStatus: 'paymentStatuses',
    plan: 'plans',
    status: 'statuses'
  };

  const getFilterField = (field: string) => {
    return filterFieldMap[field] || field;
  };

  const handleFilter = (field: string, values: any) => {
    const filterField = getFilterField(field);

    const filterValues = values.length > 0 ? (Array.isArray(values) ? values : [values]) : undefined;

    const updatedParams = {
      ...queryParams,
      [filterField]: filterValues,
      page: 1
    };

    updateQueryParams(updatedParams);
  };

  const handleTabChange = (tab: 'members' | 'groups') => {
    setActiveTab(tab);
    updateQueryParams({
      page: 1,
      paymentStatuses: undefined,
      plans: undefined,
      search: '',
      status: undefined,
      statuses: undefined
    });
  };

  return (
    <Layout>
      <div className="flex justify-between gap-4 py-10">
        <div className="flex items-center gap-3 text-2xl font-semibold">
          <FormattedMessage id={translations.pages.members.members} />
        </div>

        <div className="flex items-center gap-2">
          <Navbar title="" />
        </div>
      </div>

      <div className="mb-6 flex gap-3">
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === 'members'
              ? 'border-b-2 border-[#ED6F4C] text-[#ED6F4C]'
              : 'text-primary border-primary border-b-2'
          }`}
          onClick={() => handleTabChange('members')}
        >
          <div className="flex items-center gap-1">
            <FormattedMessage
              id={translations.pages.members.members}
              values={{ count: membersData?.page.total ?? 0 }}
            />
            <span>({membersData?.page.total ?? 0})</span>
          </div>
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === 'groups'
              ? 'border-b-2 border-[#ED6F4C] text-[#ED6F4C]'
              : 'text-primary border-primary border-b-2'
          }`}
          onClick={() => handleTabChange('groups')}
        >
          <div className="flex items-center gap-1">
            <FormattedMessage id={translations.pages.groups.groups} values={{ count: groupsData?.page.total ?? 0 }} />
            <span>({groupsData?.page.total ?? 0})</span>
          </div>
        </button>
      </div>

      {activeTab === 'groups' ? (
        <div className="space-y-9">
          <div className="flex items-center justify-between gap-6">
            <DebouncedInput
              as={SearchInput}
              className="w-72"
              initialValue={groupsSearch}
              onChange={(search: string) => {
                setGroupsSearch(search);
                updateQueryParams({ page: 1 });
              }}
              placeholder={intl.formatMessage({ id: translations.pages.groups.searchGroupsBy })}
            />

            <Button
              appearance="primary"
              className="flex items-center justify-center gap-2"
              onClick={() => createGroupModal.open().catch(() => null)}
            >
              <PlusIcon className="h-4 w-4" />
              <FormattedMessage id={translations.buttons.newGroup} />
            </Button>
          </div>
          <LoadingOverlay
            {...{ error: groupsError, loading: groupsLoading }}
            className={groupsError ? 'mt-24' : undefined}
          >
            <GroupsList
              className="mt-4"
              empty={!groupsData?.items?.length}
              groups={groupsData?.items}
              noResults={false}
              onGroupDeleted={() => {
                mutate(groupsKey);
                mutate(['groups/find', queryParams]);
              }}
            />
          </LoadingOverlay>

          {!!groupsData && (
            <Pagination
              className="mt-9"
              currentPage={groupsData.page.pageNumber}
              onPageChange={(page: number) => updateQueryParams({ page })}
              pageSize={groupsData.page.pageSize}
              totalItems={groupsData.page.total}
            />
          )}
        </div>
      ) : (
        <>
          <div className="flex justify-between gap-4">
            {hasMembers && (
              <>
                <div className="flex items-center gap-6">
                  <DebouncedInput
                    as={SearchInput}
                    className="w-72"
                    initialValue={membersSearch}
                    onChange={(search: string) => {
                      setMembersSearch(search);
                      updateQueryParams({ page: 1 });
                    }}
                    placeholder={intl.formatMessage({ id: translations.pages.members.searchMembersBy })}
                  />

                  {/* <Select
                    className="w-44"
                    getSelectedDisplayName={({ selectedItems }) =>
                      selectedItems[0]?.name ?? intl.formatMessage({ id: translations.pages.members.allMembers })
                    }
                    items={[
                      { name: intl.formatMessage({ id: translations.pages.members.allMembers }), value: undefined },
                      {
                        name: intl.formatMessage({ id: translations.pages.members.activeMembers }),
                        value: 'active'
                      },
                      { name: intl.formatMessage({ id: translations.pages.members.inactiveMembers }), value: 'inactive' }
                    ]}
                    onChange={(value: 'active' | 'inactive' | undefined) => updateQueryParams({ page: 1, status: value })}
                    placeholder={intl.formatMessage({ id: translations.placeholders.selectStatus })}
                    searchable
                    value={queryParams.status}
                  /> */}
                </div>

                {!isAdmin && (
                  <Dropdown
                    alignRight
                    appearance="primary"
                    buttonText={
                      <div className="flex items-center justify-center gap-2">
                        <PlusIcon className="h-4 w-4" />
                        <FormattedMessage id={translations.pages.members.addMember} />
                      </div>
                    }
                  >
                    <div className="flex justify-start">
                      <Button
                        className="flex w-48 items-center justify-center gap-2 rounded-xl"
                        onClick={() => {
                          invitationModal.open().catch(() => null);
                        }}
                      >
                        <div className="flex w-full items-center justify-between gap-2">
                          <FormattedMessage id={translations.buttons.addMembers} />
                          <ChevronRightIcon className="h-3 w-3 stroke-2 text-[#ED6F4C]" />
                        </div>
                      </Button>
                    </div>

                    <Button
                      as={RouterLink}
                      className="flex justify-center rounded-md rounded-t-none text-sm hover:bg-gray-200"
                      to="/import-members"
                    >
                      <div className="flex w-full items-center justify-between gap-2">
                        <FormattedMessage id={translations.pages.members.importMembers} />
                        <ChevronRightIcon className="h-3 w-3 stroke-2 text-[#ED6F4C]" />
                      </div>
                    </Button>
                  </Dropdown>
                )}
              </>
            )}
          </div>

          <div className="space-y-9 ">
            <LoadingOverlay
              className="flex min-h-[200px] items-center justify-center"
              error={membersError}
              loading={membersLoading && !membersData}
            >
              {isAdmin && !communityId ? (
                <AdminMembersList
                  className="mt-4"
                  empty={!hasMembers}
                  members={membersData?.items as MemberDto[]}
                  noResults={noResultsFound}
                />
              ) : (
                <MembersList
                  communityId={community?.id || (communityId as string | undefined)}
                  empty={!hasMembers}
                  members={membersData?.items as MemberDto[]}
                  noResults={noResultsFound}
                  onAction={() => {
                    invitationModal.open().catch(() => null);
                  }}
                  onFilter={handleFilter}
                  onReload={onReload}
                />
              )}
            </LoadingOverlay>

            {!!membersData && !!hasMembers && (
              <Pagination
                className="mt-9"
                currentPage={membersData.page.pageNumber}
                onPageChange={(page: number) => updateQueryParams({ page })}
                pageSize={membersData.page.pageSize}
                totalItems={membersData.page.total}
              />
            )}
          </div>
        </>
      )}

      <Modal
        onInvite={async (email) => {
          await apiClient.community
            .addCommunityMembers({
              communityId: community!.id,
              requestBody: { emails: [email] }
            })
            .then(() => onReload());
        }}
        schema={emailSchema}
        successMessage={<FormattedMessage id={translations.pages.members.invitedMember} />}
        {...invitationModal.props}
        description={<FormattedMessage id={translations.pages.members.inviteMember} />}
        title={<FormattedMessage id={translations.buttons.addMembers} />}
      />

      <Modal
        {...createGroupModal.props}
        onCreateGroup={async (name: string) => {
          await apiClient.group
            .createGroup({
              communityId: isAdmin ? undefined : community?.id,
              requestBody: {
                members: {},
                name
              }
            })
            .then((group) => navigate(`/groups/${group.id}`));
        }}
      />
    </Layout>
  );
};
