/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMessageDto } from '../models/CreateMessageDto';
import type { GroupsAndUserIdsDto } from '../models/GroupsAndUserIdsDto';
import type { MessageDto } from '../models/MessageDto';
import type { MessagePageDto } from '../models/MessagePageDto';
import type { MessageRecipientPageDto } from '../models/MessageRecipientPageDto';
import type { UploadIntentDto } from '../models/UploadIntentDto';
import type { UploadRequestBody } from '../models/UploadRequestBody';
import type { UserIdsDto } from '../models/UserIdsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MessageService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Return a page of messages.
   * @returns MessagePageDto Returns a page of messages.
   * @throws ApiError
   */
  public findMessages({
communityId,
resource,
resourceType,
searchQuery,
pageSize,
pageNumber,
}: {
/**
 * The id of the community.
 */
communityId?: string,
/**
 * The attachment resource.
 */
resource?: string,
/**
 * The attachment resource type.
 */
resourceType?: string,
/**
 * text to search by.
 */
searchQuery?: string,
pageSize?: number,
pageNumber?: number,
}): CancelablePromise<MessagePageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/messages/find',
      query: {
        'communityId': communityId,
        'resource': resource,
        'resourceType': resourceType,
        'searchQuery': searchQuery,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Create a message.
   * @returns MessageDto Returns the created message.
   * @throws ApiError
   */
  public createMessage({
requestBody,
communityId,
}: {
requestBody: CreateMessageDto,
/**
 * The id of the community this message is associated with.
 */
communityId?: string,
}): CancelablePromise<MessageDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/messages/create',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Initiate message attachment upload.
   * @returns UploadIntentDto Returns an upload intent.
   * @throws ApiError
   */
  public initiateMessageAttachmentUpload({
communityId,
requestBody,
}: {
/**
 * The id of the community.
 */
communityId: string,
/**
 * Only `image/jpeg`, `image/png`, `application/pdf` are accepted as mimeType.
 */
requestBody: UploadRequestBody,
}): CancelablePromise<UploadIntentDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/messages/initiate-attachment-upload',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Return message details.
   * @returns MessageDto Returns the message.
   * @throws ApiError
   */
  public getMessageDetails({
messageId,
}: {
/**
 * The id of the message.
 */
messageId: string,
}): CancelablePromise<MessageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/messages/get-details',
      query: {
        'messageId': messageId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Delete a message.
   * @returns void 
   * @throws ApiError
   */
  public deleteMessage({
messageId,
}: {
/**
 * The id of the message.
 */
messageId: string,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/messages/delete',
      query: {
        'messageId': messageId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Returns the recipients of this message.
   * @returns MessageRecipientPageDto Returns a page of recipients.
   * @throws ApiError
   */
  public findMessageRecipients({
messageId,
pageSize,
pageNumber,
}: {
/**
 * The id of the message.
 */
messageId: string,
pageSize?: number,
pageNumber?: number,
}): CancelablePromise<MessageRecipientPageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/messages/find-recipients',
      query: {
        'messageId': messageId,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Add recipients to this message.
   * @returns void 
   * @throws ApiError
   */
  public addMessageRecipients({
messageId,
requestBody,
}: {
/**
 * The id of the message.
 */
messageId: string,
requestBody: GroupsAndUserIdsDto,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/messages/add-recipients',
      query: {
        'messageId': messageId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Remove recipients from message.
   * @returns void 
   * @throws ApiError
   */
  public removeRecipientsFromMessage({
messageId,
requestBody,
}: {
/**
 * The id of the message.
 */
messageId: string,
requestBody: UserIdsDto,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/messages/remove-recipients',
      query: {
        'messageId': messageId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Update message.
   * @returns MessageDto Returns the message.
   * @throws ApiError
   */
  public updateMessage({
messageId,
requestBody,
}: {
/**
 * The id of the message.
 */
messageId: string,
requestBody: CreateMessageDto,
}): CancelablePromise<MessageDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/messages/update',
      query: {
        'messageId': messageId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Send message.
   * @returns any Request succeeded without response.
   * @throws ApiError
   */
  public sendMessage({
messageId,
}: {
/**
 * The id of the message.
 */
messageId: string,
}): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/messages/send',
      query: {
        'messageId': messageId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
        409: `Message already sent.`,
      },
    });
  }

}
