import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locales';

import { Button } from '@/components/Button';
import { CenteredBoxLayout } from '@/components/CenteredBoxLayout';
import { Form } from '@/components/Form';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { LoadingButton } from '@/components/LoadingButton';
import { SubmitFeedback } from '@/components/SubmitFeedback';
import { authApiClient } from '@/services/auth-api';

import arrowBack from '@/assets/arrow-back.png';
import backgroundImg from '@/assets/background-forgot-password.png';
import { Input } from '@/components/Form/Input';

export const schema = yup
  .object({
    email: yup.string().trim().email().required().label(translations.fields.emailAddress)
  })
  .required();

export const ForgotPasswordPage: React.FC = () => {
  const onSubmit = React.useCallback(async (values: yup.InferType<typeof schema>) => {
    await authApiClient.authentication.initiateResetPassword({ requestBody: values });
    setDisabledButton(true);
  }, []);

  const [disabledButton, setDisabledButton] = React.useState(false);
  const [secondsButton, setSecondsButton] = React.useState(59);

  React.useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (disabledButton) {
      intervalId = setInterval(() => {
        setSecondsButton((prevSeconds) => {
          console.log(disabledButton);

          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            clearInterval(intervalId);
            setDisabledButton(false);
            return 59;
          }
        });
      }, 1500);
    }
    return () => clearInterval(intervalId);
  }, [disabledButton]);

  return (
    <CenteredBoxLayout
      backgroundImg={backgroundImg}
      goBack={<img alt="arrow back" className={''} src={arrowBack} />}
      subhead={<FormattedMessage id={translations.pages.forgotPassword.subhead} />}
      title={<FormattedMessage id={translations.pages.forgotPassword.title} />}
    >
      <Form {...{ onSubmit, schema }}>
        {({ submitting, submitError, submitSucceeded, dirtySinceLastSubmit }) => (
          <div className="space-y-6">
            <ValidatedField
              field={Input}
              id="email"
              label={<FormattedMessage id={translations.fields.emailAddress} />}
              name="email"
              placeholder="E-mail"
              readOnly={submitting}
              type="email"
            />

            <Button
              appearance="primary"
              as={LoadingButton}
              className="w-full"
              disabledButton={disabledButton}
              loading={submitting}
              type="submit"
            >
              {disabledButton ? (
                `Wait ${secondsButton} seconds to resend the email`
              ) : (
                <FormattedMessage id={translations.pages.forgotPassword.cta} />
              )}
            </Button>

            <SubmitFeedback
              {...{ submitError, submitSucceeded }}
              show={!submitting && !dirtySinceLastSubmit}
              successMessage={<FormattedMessage id={translations.pages.forgotPassword.submitted} />}
            />
          </div>
        )}
      </Form>
    </CenteredBoxLayout>
  );
};
