import React from 'react';

import checkboxFalsePath from '@/assets/images/checkbox-false.svg';
import checkboxTruePath from '@/assets/images/checkbox-true.svg';
import multipleFilterPath from '@/assets/images/multiple-filter.svg';
import singleFilterPath from '@/assets/images/single-filter.svg';
import { Appearance, Badge } from '@/components/Badge';
import { Appearance as ButtonAppearance, Button } from '@/components/Button';

interface FilterOption {
  label: string;
  value: string | number;
}

interface ColumnFilter {
  type: 'single' | 'multiple';
  options: FilterOption[];
}

export interface Column<T> {
  header: string;
  accessor: keyof T | string;
  cell?: (item: T) => React.ReactNode;
  filter?: ColumnFilter;
  width?: string;
}

export interface TableAction<T> {
  label: string;
  onClick: (selectedItems: T[]) => void;
  appearance?: ButtonAppearance;
  icon?: React.ReactNode;
}

interface DataTableProps<T> {
  data: T[];
  columns: Column<T>[];
  onRowClick?: (item: T) => void;
  isLoading?: boolean;
  onFilter?: (field: string, values: (string | number)[]) => void;
  selectable?: boolean;
  onSelectionChange?: (selectedItems: T[]) => void;
  actions?: TableAction<T>[];
  shouldShowHeader?: boolean;
}

export function DataTable<T extends Record<string, any>>({
  data,
  columns,
  onRowClick,
  isLoading,
  onFilter,
  selectable = false,
  onSelectionChange,
  actions = [],
  shouldShowHeader = true
}: DataTableProps<T>) {
  const [selectedRows, setSelectedRows] = React.useState<T[]>([]);
  const [selectedFilters, setSelectedFilters] = React.useState<Record<string, (string | number)[]>>({});

  const handleFilterChange = (field: string, type: 'single' | 'multiple', value: string | number) => {
    setSelectedFilters((prev) => {
      const newFilters = { ...prev };

      if (type === 'single') {
        const currentValue = prev[field]?.[0];
        if (!currentValue) {
          newFilters[field] = [`${field},ASC`];
        } else if (typeof currentValue === 'string' && currentValue.includes('ASC')) {
          newFilters[field] = [`${field},DESC`];
        } else {
          delete newFilters[field];
        }
      } else {
        const currentValues = prev[field] || [];
        if (currentValues.includes(value)) {
          newFilters[field] = currentValues.filter((v) => v !== value);
        } else {
          newFilters[field] = [...currentValues, value];
        }
      }

      onFilter?.(field, newFilters[field] || []);
      return newFilters;
    });
  };

  const FilterIcon = ({ type }: { type: 'single' | 'multiple' }) => {
    const iconPath = type === 'single' ? singleFilterPath : multipleFilterPath;

    return <img alt={`${type} filter`} className="h-4 w-4" src={iconPath} />;
  };

  const FilterComponent = ({ column }: { column: Column<T> }) => {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const dropdownRef = React.useRef<HTMLDivElement>(null);

    const isAllSelected = React.useMemo(() => {
      if (!column.filter?.options) return false;
      const currentValues = selectedFilters[column.accessor as string] || [];
      return currentValues.length === column.filter.options.length;
    }, [selectedFilters, column.filter?.options, column.accessor]);

    const handleSelectAllFilters = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const allValues = column.filter?.options.map((opt) => opt.value) || [];
      const newValues = isAllSelected ? [] : allValues;

      setSelectedFilters((prev) => {
        const newFilters = { ...prev };
        newFilters[column.accessor as string] = newValues;
        onFilter?.(column.accessor as string, newValues);
        return newFilters;
      });
    };

    React.useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (target.closest('.dropdown-content')) {
          return;
        }

        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setDropdownOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    if (!column.filter) return null;

    if (column.filter.type === 'single') {
      return (
        <Button
          appearance="filter"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            handleFilterChange(column.accessor as string, 'single', column.accessor as string);
          }}
          shouldUseShadow={false}
        >
          <FilterIcon type="single" />
        </Button>
      );
    }

    return (
      <div className="relative" ref={dropdownRef}>
        <Button
          appearance="filter"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            setDropdownOpen(!dropdownOpen);
          }}
          shouldUseShadow={false}
        >
          <FilterIcon type={column.filter?.type || 'single'} />
        </Button>

        {dropdownOpen && (
          <div className="dropdown-content absolute z-10 mt-2 w-48 rounded-md bg-white py-2 shadow-lg">
            {column.filter?.type === 'multiple' && (
              <>
                <div
                  className="flex w-full cursor-pointer items-center gap-2 px-3 py-2 hover:bg-gray-50"
                  onClick={handleSelectAllFilters}
                >
                  <img alt="checkbox" className="h-5 w-5" src={isAllSelected ? checkboxTruePath : checkboxFalsePath} />
                  <span className="w-full text-left text-sm">All status</span>
                </div>
                <div className="my-2 h-px bg-gray-200" />
              </>
            )}

            {column.filter?.options.map((option, index) => (
              <div key={option.value}>
                {column.filter?.type === 'multiple' ? (
                  <div
                    className="flex w-full cursor-pointer items-center gap-2 px-3 py-2 hover:bg-gray-50"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleFilterChange(column.accessor as string, 'multiple', option.value);
                    }}
                  >
                    <img
                      alt="checkbox"
                      className="h-5 w-5"
                      src={
                        (selectedFilters[column.accessor as string] || []).includes(option.value)
                          ? checkboxTruePath
                          : checkboxFalsePath
                      }
                    />
                    <div className="flex items-center">
                      <Badge appearance={option.value.toString().toLowerCase() as Appearance}>{option.label}</Badge>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`w-full cursor-pointer px-3 py-2 hover:bg-gray-50 ${
                      (selectedFilters[column.accessor as string] || []).includes(option.value) ? 'bg-gray-50' : ''
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleFilterChange(column.accessor as string, 'single', option.value);
                    }}
                  >
                    <Badge appearance={option.value.toString().toLowerCase() as Appearance}>{option.label}</Badge>
                  </div>
                )}
                {index < (column.filter?.options ?? []).length - 1 && <div className="my-2 h-px bg-gray-200" />}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const handleSelectAll = (e: React.MouseEvent) => {
    e.stopPropagation();
    const newSelection = selectedRows.length === data.length ? [] : [...data];
    setSelectedRows(newSelection);
    onSelectionChange?.(newSelection);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full">
      <table className="min-w-full table-auto">
        {shouldShowHeader && (
          <thead>
            <tr className="h-[52px] rounded-lg bg-transparent shadow-[0px_0px_24px_rgba(202,202,202,0.35)]">
              {selectable && (
                <th className="h-full w-[52px]">
                  <Button
                    appearance="filterIcon"
                    className="flex h-full w-full items-center justify-center"
                    onClick={handleSelectAll}
                    shouldUseShadow={false}
                  >
                    <div className="h-7 w-7">
                      <img
                        alt="select all"
                        className="h-7 w-7"
                        src={selectedRows.length === data.length ? checkboxTruePath : checkboxFalsePath}
                      />
                    </div>
                  </Button>
                </th>
              )}
              {selectedRows.length > 0 ? (
                <th className="px-6 py-4" colSpan={columns.length}>
                  <div className="flex items-center gap-2">
                    {actions.map((action, index) => (
                      <Button
                        appearance={action.appearance || 'primary'}
                        key={index}
                        onClick={() => action.onClick(selectedRows)}
                      >
                        <div className="flex items-center gap-2">
                          {action.icon}
                          {action.label}
                        </div>
                      </Button>
                    ))}
                  </div>
                </th>
              ) : (
                columns.map((column, index) => (
                  <th
                    className={`px-6 py-4 text-left text-xs font-medium capitalize tracking-wider text-gray-500`}
                    key={index}
                    style={{ width: column.width }}
                  >
                    <div className="flex items-center gap-2">
                      {column.header}
                      {column.filter && <FilterComponent column={column} />}
                    </div>
                  </th>
                ))
              )}
            </tr>
          </thead>
        )}
        <div className="mt-4 block"></div>
        {/* Body */}
        <tbody>
          {data.map((item, rowIndex) => (
            <tr
              className={`rounded-lg ${onRowClick ? 'cursor-pointer' : ''} 
                ${rowIndex % 2 === 0 ? 'bg-white hover:bg-gray-50' : 'bg-gray-100 hover:bg-white'}`}
              key={rowIndex}
              onClick={() => onRowClick?.(item)}
            >
              {selectable && (
                <td className="w-[52px] rounded-l-lg">
                  <Button
                    appearance="filter"
                    className="flex items-center justify-center"
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      const newSelection = selectedRows.includes(item)
                        ? selectedRows.filter((row) => row !== item)
                        : [...selectedRows, item];
                      setSelectedRows(newSelection);
                      onSelectionChange?.(newSelection);
                    }}
                    shouldUseShadow={false}
                  >
                    <div className="h-7 w-7">
                      <img
                        alt="select row"
                        className="h-7 w-7"
                        src={selectedRows.includes(item) ? checkboxTruePath : checkboxFalsePath}
                      />
                    </div>
                  </Button>
                </td>
              )}
              {columns.map((column, colIndex) => (
                <td
                  className={`whitespace-nowrap p-4 py-4 text-sm text-gray-900 ${
                    colIndex === 0 && !selectable ? 'rounded-l-lg' : ''
                  } ${colIndex === columns.length - 1 ? 'rounded-r-lg' : ''}`}
                  key={`${rowIndex}-${colIndex}`}
                  style={{ width: column.width }}
                >
                  {column.cell ? column.cell(item) : item[column.accessor as keyof T]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
