import { Button } from '@/components/Button';
import { Layout } from '@/components/Layout';
import { LoadingIndicator, LoadingOverlay } from '@/components/Loading';
import { MemberProfile } from '@/components/Profile';
import { Column, DataTable } from '@/components/Table/DataTable';
import { useModal } from '@/hooks/useModal';
import { translations } from '@/locales';
import { apiClient } from '@/services/api';
import { ChevronRightIcon, PlusIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { LoadingButton } from '@/components/LoadingButton';
import { SubmitFeedback } from '@/components/SubmitFeedback';
import * as yup from 'yup';

import { ValidatedField } from '@/components/Form/ValidatedField';
import { Link } from '@/components/Link';

import { DebouncedInput } from '@/components/Form/DebouncedInput';
import { SearchInput } from '@/components/Form/SearchInput';
import './styles.css';

import { Modal } from '@/components/Modal';
import { BasicConfirmationModal } from '@/components/Modal/BasicConfirmationModal';
import { Navbar } from '@/components/Navbar';
import { Pagination } from '@/components/Pagination';
import { page, search, size, useQueryParams } from '@/hooks/useQueryParams';
import { AddMemberModal } from './AddMemberModal';

export const schema = yup
  .object({
    name: yup.string().trim().required().label(translations.fields.groupName)
  })
  .required();

const config = { page, search, size };

export const GroupDetailsPage: React.FC = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const [queryParams, updateQueryParams] = useQueryParams({ config });

  const isNew = !groupId;

  const [membersCount, setMembersCount] = useState(0);

  const {
    data: members,
    isLoading: membersLoading,
    error: membersError,
    mutate: onMembersReload
  } = useSWR(groupId ? ['groups/find-members', groupId, queryParams] : null, () =>
    apiClient.group.findGroupMembers({
      groupId: groupId!,
      pageNumber: queryParams.page,
      pageSize: queryParams.size,
      searchQuery: queryParams.search
    })
  );

  const hasMembers = members && members?.page.total > 0;

  React.useEffect(() => {
    if (members) {
      setMembersCount(members.page.total);
    }
  }, [members]);

  const {
    data: loadedGroup,
    isLoading: loading,
    mutate: onReload
  } = useSWR(isNew ? null : 'groups/get-details', () => apiClient.group.getGroupDetails({ groupId: groupId! }), {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: false
  });

  const initialValues = loadedGroup;

  const addMembersModal = useModal(AddMemberModal);
  const deleteGroupModal = useModal(BasicConfirmationModal);
  const removeMemberModal = useModal(BasicConfirmationModal);

  const intl = useIntl();

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      await apiClient.group
        .updateGroupDetails({ groupId: loadedGroup!.id, requestBody: values })
        .then(() => onReload());
    },
    [loadedGroup, onReload]
  );

  const columns: Column<any>[] = [
    {
      accessor: 'name',
      cell: (member) => (
        <MemberProfile
          email={member.email}
          firstName={member.firstName}
          lastName={member.lastName}
          src={member.image}
        />
      ),
      header: 'Name',
      width: '90%'
    },
    {
      accessor: 'actions',
      cell: (member) => (
        <Button
          appearance="deleteTertiary"
          className="rounded-xl"
          onClick={() => removeMemberModal.open(member).catch(() => null)}
        >
          <FormattedMessage id={translations.buttons.remove} />
        </Button>
      ),
      header: '',
      width: '10%'
    }
  ];

  return (
    <Layout>
      <div className="flex items-center justify-between gap-4 py-10">
        <div className="flex items-center gap-5">
          <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/members">
            <FormattedMessage id={translations.pages.members.members} />
          </Link>

          <ChevronRightIcon className="w-5 text-sm text-gray-400" />

          <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/members?tab=groups">
            <FormattedMessage id={translations.pages.groups.groups} />
          </Link>

          <ChevronRightIcon className="w-5 text-sm text-gray-400" />

          <span className="text-sm font-medium text-[#ED6F4C]">
            <FormattedMessage id={translations.pages.groups.edit} />
          </span>
        </div>

        <div className="flex items-center gap-2">
          <Navbar title="" />
        </div>
      </div>

      <div className="px-96">
        <Form {...{ initialValues, onSubmit, schema }}>
          {({ submitting, values, submitSucceeded, submitError, dirtySinceLastSubmit }) => (
            <div className="my-5 space-y-6">
              <div className="flex items-center justify-between pb-10 text-2xl font-semibold">
                <LoadingOverlay {...{ loading }} displayLoader>
                  Edit {values?.name?.trim() || initialValues?.name?.trim()}
                </LoadingOverlay>

                <Button
                  appearance="deleteSecondary"
                  className="border-2 border-red-500 font-medium"
                  onClick={() => deleteGroupModal.open().catch(() => null)}
                  type="button"
                >
                  <span className="whitespace-nowrap">
                    <FormattedMessage id={translations.buttons.deleteGroup} />
                  </span>
                </Button>
              </div>

              <div className="flex flex-col gap-5">
                <span className="text-general-info">
                  <FormattedMessage id={translations.pages.groups.generalInformation} />
                </span>

                <ValidatedField
                  field={InputWithLabel}
                  id="name"
                  name="name"
                  placeholder={intl.formatMessage({ id: translations.pages.groups.createPlaceholder })}
                  readOnly={submitting}
                />

                <div className="flex items-center justify-end gap-6">
                  <SubmitFeedback {...{ submitError, submitSucceeded }} show={!submitting && !dirtySinceLastSubmit} />

                  <Button appearance="primary" as={LoadingButton} className="w-48" loading={submitting} type="submit">
                    <FormattedMessage id={translations.buttons.save} />
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Form>

        <div>
          <div className="mr-4 flex items-center gap-3 text-2xl font-semibold">
            <FormattedMessage id={translations.pages.members.membersWithValue} values={{ count: membersCount }} />

            {!!loading && <LoadingIndicator className="h-6 w-6" />}
          </div>
          <div className="flex items-center justify-between gap-6 py-5">
            <DebouncedInput
              as={SearchInput}
              className="w-72"
              initialValue={queryParams.search}
              onChange={(search: string) => updateQueryParams({ page: 1, search })}
              placeholder={intl.formatMessage({ id: translations.pages.members.searchByTitle })}
            />

            <Button
              appearance="primary"
              className="flex w-44 justify-center gap-2"
              onClick={() => groupId && addMembersModal.open({ groupId }).catch(() => null)}
            >
              <PlusIcon className="h-5 w-5" />
              <FormattedMessage id={translations.buttons.addMember} />
            </Button>
          </div>
        </div>
        <LoadingOverlay error={membersError} loading={membersLoading}>
          {hasMembers ? (
            <div className="flex flex-col justify-between">
              <DataTable columns={columns} data={members.items} shouldShowHeader={false} />
            </div>
          ) : (
            <span className="no-members-text flex items-center justify-center pt-20">
              <FormattedMessage id={translations.pages.members.noMembers} />
            </span>
          )}
        </LoadingOverlay>
        {!!members && !!hasMembers && (
          <Pagination
            className="mt-9"
            currentPage={members.page.pageNumber}
            onPageChange={(page: number) => updateQueryParams({ page })}
            pageSize={members.page.pageSize}
            totalItems={members.page.total}
          />
        )}
      </div>

      <Modal
        onInvite={async (groupIds, membersIds) => {
          await apiClient.group
            .addGroupMembers({
              groupId: loadedGroup!.id,
              requestBody: { groupIds, userIds: membersIds }
            })
            .then(() => onMembersReload());
        }}
        {...addMembersModal.props}
      />

      <Modal
        buttonMessage={<FormattedMessage id={translations.buttons.delete} />}
        {...deleteGroupModal.props}
        onConfirmation={async () => {
          await apiClient.group.deleteGroup({ groupId: groupId! }).then(() => navigate('/members?tab=groups'));
        }}
        subhead={<FormattedMessage id={translations.pages.groups.deleteGroupDescription} />}
        title={
          <FormattedMessage id={translations.pages.groups.deleteGroupTitle} values={{ name: loadedGroup?.name }} />
        }
      />

      <Modal
        {...removeMemberModal.props}
        buttonMessage={<FormattedMessage id={translations.buttons.removeMember} />}
        onConfirmation={async (memberId) => {
          await apiClient.group
            .removeMembersFromGroup({ groupId: loadedGroup!.id, requestBody: { userIds: [memberId!] } })
            .then(() => onMembersReload());
        }}
        subhead={<FormattedMessage id={translations.pages.groups.removeMemberHelp} />}
        title={<FormattedMessage id={translations.pages.groups.removeMemberTitle} />}
      />
    </Layout>
  );
};
