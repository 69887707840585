import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useSWR from 'swr';
import * as yup from 'yup';

import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { DebouncedInput } from '@/components/Form/DebouncedInput';
import { SearchInput } from '@/components/Form/SearchInput';
import { LoadingOverlay } from '@/components/Loading';
import { LoadingButton } from '@/components/LoadingButton';
import { InstanceProps } from '@/components/Modal/Modal';
import { ModalHeader } from '@/components/Modal/ModalHeader';
import { ModalLayout } from '@/components/Modal/ModalLayout';
import { Pagination } from '@/components/Pagination';
import { MemberProfile } from '@/components/Profile';
import { Column, DataTable } from '@/components/Table/DataTable';
import { page, paymentStatuses, plans, search, size, useQueryParams } from '@/hooks/useQueryParams';
import { useRole } from '@/hooks/useRole';
import { translations } from '@/locales';
import { apiClient } from '@/services/api';
import { MemberDto } from '@billy/management-api-sdk';
import { FormApi } from 'final-form';

const config = { page, paymentStatuses, plans, search, size };

const schema = yup
  .object()
  .shape(
    {
      groupsIds: yup
        .array()
        .of(yup.string().required().label(translations.fields.id))
        .when('members', {
          is: (value: unknown[]) => !!value && value.length > 0,
          otherwise: yup.array().min(1, translations.customValidation.membersSelect).required()
        }),
      members: yup
        .array()
        .of(
          yup.object({
            email: yup.string().email().required().label(translations.fields.emailAddress),
            firstName: yup.string().label(translations.fields.firstName),
            id: yup.string().required().label(translations.fields.id),
            lastName: yup.string().label(translations.fields.lastName)
          })
        )
        .when('groupsIds', {
          is: (value: unknown[]) => !!value && value.length > 0,
          otherwise: yup.array().min(1, translations.customValidation.membersSelect).required()
        })
    },
    [['groupsIds', 'members']]
  )
  .required();

interface ModalProps extends InstanceProps<undefined, { groupId: string }> {
  onInvite(groupsIds: string[], membersIds: string[]): Promise<void>;
}

export const AddMemberModal: React.FC<ModalProps> = ({ onClose, onInvite, className, data }) => {
  const intl = useIntl();
  const { community } = useRole();
  const { groupId } = data || {};

  const [queryParams, updateQueryParams] = useQueryParams({ config });
  const [membersSearch, setMembersSearch] = useState('');
  const [membersToAdd, setMembersToAdd] = useState<MemberDto[]>([]);

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>, form: FormApi) => {
      await onInvite(
        [groupId!],
        membersToAdd.map((member) => member.id)
      );
      form.restart();
      onClose();
    },
    [groupId, membersToAdd, onClose, onInvite]
  );

  const {
    data: members,
    isLoading: membersLoading,
    error: membersError,
    mutate: onMembersReload
  } = useSWR(
    ['communities/find-members', queryParams, membersSearch],
    () =>
      apiClient.community.findCommunityMembers({
        communityId: community!.id,
        pageNumber: queryParams.page,
        pageSize: queryParams.size,
        searchQuery: membersSearch
      }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  );

  const hasMembers = !!members && members.page.total > 0;

  const columns: Column<any>[] = [
    {
      accessor: 'name',
      cell: (member) => (
        <MemberProfile
          email={member.email}
          firstName={member.firstName}
          lastName={member.lastName}
          src={member.image}
        />
      ),
      header: 'Name',
      width: '95%'
    },
    {
      accessor: 'actions',
      cell: (member) => {
        const isSelected = membersToAdd.some((m) => m.id === member.id);
        return (
          <div className="flex justify-end">
            <Button
              appearance={isSelected ? 'deleteTertiary' : 'success'}
              className="rounded-xl"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                handleRowClick(member);
              }}
              type="button"
            >
              <FormattedMessage id={isSelected ? translations.buttons.remove : translations.buttons.add} />
            </Button>
          </div>
        );
      },
      header: '',
      width: '5%'
    }
  ];

  const handleRowClick = (member: MemberDto) => {
    setMembersToAdd((prevMembers) => {
      if (prevMembers.some((m) => m.id === member.id)) {
        return prevMembers.filter((m) => m.id !== member.id);
      } else {
        return [...prevMembers, member];
      }
    });
  };

  return (
    <ModalLayout {...{ className }}>
      <ModalHeader>
        <FormattedMessage id={translations.pages.groups.addMembers} />
      </ModalHeader>

      <Form {...{ onSubmit }}>
        {({ submitting }) => (
          <div className="space-y-4">
            <span>
              <FormattedMessage id={translations.pages.groups.addMembersDescription} />
            </span>

            <div className="flex justify-start">
              <DebouncedInput
                as={SearchInput}
                className="w-72"
                initialValue={membersSearch}
                onChange={(search: string) => {
                  setMembersSearch(search);
                  updateQueryParams({ page: 1 });
                  onMembersReload();
                }}
                placeholder={intl.formatMessage({ id: translations.pages.members.searchByTitle })}
              />
            </div>

            <LoadingOverlay error={membersError} loading={membersLoading}>
              {hasMembers ? (
                <div className="flex flex-col justify-between">
                  <DataTable columns={columns} data={members.items} shouldShowHeader={false} />
                </div>
              ) : (
                <span className="no-members-text flex items-center justify-center pt-20">
                  <FormattedMessage id={translations.pages.members.noMembers} />
                </span>
              )}
            </LoadingOverlay>

            {hasMembers && (
              <Pagination
                currentPage={queryParams.page}
                onPageChange={(page: number) => updateQueryParams({ page })}
                pageSize={queryParams.size}
                totalItems={members?.page.total}
              />
            )}

            <div className="flex gap-4">
              <Button appearance="basicSecondary" className="w-full" onClick={onClose} type="button">
                <FormattedMessage id={translations.buttons.cancel} />
              </Button>

              <Button appearance="primary" as={LoadingButton} className="w-full" loading={submitting} type="submit">
                <FormattedMessage id={translations.buttons.confirmMembers} />
              </Button>
            </div>
          </div>
        )}
      </Form>
    </ModalLayout>
  );
};
