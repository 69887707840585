import React, { useState } from 'react';

import { Button, Props as ButtonProps } from './Button';
import { LoadingButton } from './LoadingButton';

export type Props = ButtonProps & {
  onClick(): Promise<void>;
  className?: string;
  type?: string;
  customLoading?: (isLoading: boolean) => React.ReactNode;
  children?: React.ReactNode;
};

export const AsyncButton: React.FC<Props> = ({ customLoading, children, onClick, ...props }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setLoading(true);

    onClick()
      .then(() => setLoading(false))
      .catch(() => {
        setLoading(false);
      });
  };

  if (customLoading) {
    return (
      <Button {...props} onClick={handleClick}>
        {customLoading(loading)}
      </Button>
    );
  }

  return (
    <LoadingButton {...props} {...{ loading }} onClick={handleClick}>
      {children}
    </LoadingButton>
  );
};
