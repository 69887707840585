import React from 'react';

import { Button } from '@/components/Button';
import { MemberDto } from '@billy/management-api-sdk';

import classNames from 'classnames';
import { InstanceProps } from './Modal';
import { ModalLayout } from './ModalLayout';

import successIcon from '@/assets/success-Icon.png';

import { Link as RouterLink } from 'react-router-dom';

import { Link } from '@/components/Link';

import backgroundImg from '@/assets/Vector.png';

interface ModalProps extends InstanceProps<undefined, Pick<MemberDto, 'email' | 'firstName' | 'id'>> {
  title: React.ReactNode;
  subhead: React.ReactNode;
}

export const SuccessModal: React.FC<ModalProps> = ({ title, subhead, className }) => {
  return (
    <ModalLayout className={classNames(className, 'max-w-modal py-0 px-0')}>
      <div className="flex flex-col items-center gap-0">
        <div
          className="flex flex-col gap-4 bg-contain bg-no-repeat p-4"
          style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundPosition: 'right bottom -55px',
            backgroundSize: '60% 130%'
          }}
        >
          <div className="h-14 w-16">
            <img alt="Success Icon" className="h-full object-contain" src={successIcon} />
          </div>

          <div>
            <span className="text-xl font-bold capitalize">{title}</span>

            <p className="mt-2 text-xs font-normal text-gray-500">{subhead}</p>
          </div>
        </div>

        <div className=" flex w-full rounded-b-lg bg-[#F9F9F9] p-4">
          <Button appearance="primary" className="w-full" type="button">
            <Link as={RouterLink} to="/login">
              Log in
            </Link>
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};
