/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateServiceDto } from '../models/CreateServiceDto';
import type { ServiceDto } from '../models/ServiceDto';
import type { ServiceMemberPageDto } from '../models/ServiceMemberPageDto';
import type { ServicePageDto } from '../models/ServicePageDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ServiceService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Return a page of services.
   * @returns ServicePageDto Returns a page of services.
   * @throws ApiError
   */
  public findServices({
communityId,
searchQuery,
pageSize,
pageNumber,
}: {
/**
 * The id of the community.
 */
communityId?: string,
/**
 * text to search by.
 */
searchQuery?: string,
pageSize?: number,
pageNumber?: number,
}): CancelablePromise<ServicePageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/services/find',
      query: {
        'communityId': communityId,
        'searchQuery': searchQuery,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Create a service.
   * @returns ServiceDto Returns the created service.
   * @throws ApiError
   */
  public createService({
communityId,
requestBody,
}: {
/**
 * The id of the community.
 */
communityId: string,
requestBody: CreateServiceDto,
}): CancelablePromise<ServiceDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/services/create',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Return service details.
   * @returns ServiceDto Returns the service.
   * @throws ApiError
   */
  public getServiceDetails({
serviceId,
}: {
/**
 * The id of the service.
 */
serviceId: string,
}): CancelablePromise<ServiceDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/services/get-details',
      query: {
        'serviceId': serviceId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Delete a service.
   * @returns void 
   * @throws ApiError
   */
  public deleteService({
serviceId,
}: {
/**
 * The id of the service.
 */
serviceId: string,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/services/delete',
      query: {
        'serviceId': serviceId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Returns a page of members part of this audience.
   * @returns ServiceMemberPageDto Returns a page of members part of this audience.
   * @throws ApiError
   */
  public findServiceAudienceMembers({
serviceId,
pageSize,
pageNumber,
}: {
/**
 * The id of the service.
 */
serviceId: string,
pageSize?: number,
pageNumber?: number,
}): CancelablePromise<ServiceMemberPageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/services/find-audience-members',
      query: {
        'serviceId': serviceId,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

}
