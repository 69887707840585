import classNames from 'classnames';
import React from 'react';

import { CheckIcon, LockClosedIcon, PencilSquareIcon, TrashIcon, UserMinusIcon } from '@heroicons/react/24/solid';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { apiClient } from '@/services/api';

import { translations } from '@/locales';

import { Auth } from '@/containers/use-auth';

import backgroundImg from '@/assets/background-lock.png';
import logoFooter from '@/assets/logo-footer.png';
import imgPlaceholder from '@/assets/user-placeholder.png';

import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { Input } from '@/components/Form/Input';
import { PasswordInput } from '@/components/Form/PasswordInput';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Layout } from '@/components/Layout';
import { LoadingButton } from '@/components/LoadingButton';

import { Modal } from '@/components/Modal';
import { DeleteBasicModal } from '@/components/Modal/DeleteBasicModal';
import { Navbar } from '@/components/Navbar';
import { SubmitFeedback } from '@/components/SubmitFeedback';
import { useModal } from '@/hooks/useModal';
import { authApiClient } from '@/services/auth-api';
import { uploadFile } from '@/utils/functions';

export const partialSchema = yup
  .object({
    email: yup.string().trim().email().required().label(translations.fields.emailAddress),
    firstName: yup.string().trim().required().label(translations.fields.firstName),
    image: yup.string().trim().optional().label(translations.fields.image),
    lastName: yup.string().trim().required().label(translations.fields.lastName)
  })
  .required();

export const schema = yup
  .object({
    actualPassword: yup.string().trim().required().label(translations.fields.password),
    newPassword: yup.string().trim().min(8).max(128).required().label(translations.fields.newPassword),
    repeatPassword: yup
      .string()
      .trim()
      .min(8)
      .max(128)
      .oneOf([yup.ref('newPassword')], translations.customValidation.matchPasswords)
      .required()
      .label(translations.fields.repeatPassword)
  })
  .required();

export const MyProfilePage: React.FC = () => {
  const { context, reload } = Auth.useContainer();
  const initialValues = {
    email: context?.email,
    firstName: context?.firstName,
    image: context?.image,
    lastName: context?.lastName
  };
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [isNameEditable, setIsNameEditable] = React.useState(false);
  const [isEmailEditable, setIsEmailEditable] = React.useState(false);
  const [emailValue, setEmailValue] = React.useState(initialValues.email);
  const [nameValue, setNameValue] = React.useState(`${initialValues.firstName} ${initialValues.lastName}`);
  const [imageURL, setImageURL] = React.useState(initialValues.image);
  const [hasImage, setHasImage] = React.useState(!!imageURL);

  const deleteAccountModal = useModal(DeleteBasicModal);

  const inputClass =
    'border-0 bg-gray-100 shadow-none disabled:bg-transparent disabled:text-black disabled:shadow-none';
  const buttonClass =
    'rounded-xl bg-gray-100 py-1 px-5 text-sm text-xs font-extrabold hover:bg-gray-200 disabled:opacity-50';
  const imageClass =
    'absolute bottom-10 -right-28 rounded-3xl border-0 py-2 px-2 text-opacity-80 shadow-none hover:text-opacity-100 disabled:text-black disabled:opacity-60';

  const handleImageButtonClick = async () => {
    setIsDisabled(true);
    let newImageURL = '';

    if (hasImage) {
      newImageURL = '';
    } else {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*';

      newImageURL = await new Promise((resolve) => {
        fileInput.onchange = async (event) => {
          const target = event.target as HTMLInputElement;
          const file = target.files?.[0];

          if (file) {
            const _imgURL = await profileImageUpload(file);

            resolve(_imgURL);
          } else {
            resolve(initialValues.image || '');
          }
        };

        fileInput.oncancel = () => resolve(initialValues.image || '');
        fileInput.click();
      });
    }

    setImageURL(newImageURL);

    await onPartialSubmit({
      email: initialValues.email!,
      firstName: initialValues.firstName!,
      image: newImageURL,
      lastName: initialValues.lastName!
    })
      .then(() => setHasImage(!!newImageURL))
      .finally(() => setIsDisabled(false));
  };

  const handleNameButtonClick = async () => {
    const [firstName, lastName] = nameValue.split(' ');

    if (firstName) {
      if (isNameEditable) {
        await onPartialSubmit({
          email: initialValues.email!,
          firstName: firstName,
          image: initialValues.image,
          lastName: lastName || ''
        });
      }

      setIsNameEditable(!isNameEditable);
      setIsDisabled(!isDisabled);
      setIsEmailEditable(false);
    }
  };

  const handleEmailButtonClick = async () => {
    if (emailValue) {
      if (isEmailEditable) {
        await onPartialSubmit({
          email: emailValue,
          firstName: initialValues.firstName!,
          image: initialValues.image,
          lastName: initialValues.lastName!
        });
      }
      setIsEmailEditable(!isEmailEditable);
      setIsDisabled(!isDisabled);
      setIsNameEditable(false);
    }
  };

  const profileImageUpload = async (file: File) => {
    const intent = await apiClient.onboarding.initiateProfileImageUpload({
      requestBody: { mimeType: file.type }
    });

    await uploadFile(intent.upload.url, intent.upload.headers, file);

    return intent.asset;
  };

  const onPartialSubmit = async (values: yup.InferType<typeof partialSchema>) => {
    await apiClient.profile.updateProfileDetails({ requestBody: values }).then(() => reload());
  };

  const onSubmit = async (values: yup.InferType<typeof schema>) => {
    await authApiClient.authentication
      .updatePassword({
        requestBody: { ...values, email: initialValues.email! }
      })
      .then(() => reload());
  };

  return (
    <Layout>
      <Navbar title={<FormattedMessage id={translations.pages.profile.myProfile} />} />

      <div className="flex w-full flex-col items-center gap-6">
        <div className="flex w-full max-w-2xl flex-col gap-3 rounded-lg border border-gray-300 p-4">
          <div className="max-display:flex-row flex h-full w-full flex-col items-center justify-between gap-8">
            <div className="relative h-36 w-36">
              <img
                alt="profile image"
                className="h-full w-full rounded-full object-cover"
                src={imageURL || imgPlaceholder}
              />
              <Button
                appearance="basic"
                className={classNames(
                  imageClass,
                  hasImage
                    ? 'bg-[#FFE2E1] text-[#FF231C] hover:bg-[#FFE2E1]'
                    : 'bg-[#E1FFE2] text-[#23FF1C] hover:bg-[#E1FFE2]'
                )}
                disabled={isDisabled}
                onClick={handleImageButtonClick}
                type="button"
              >
                {hasImage ? <TrashIcon className="h-4 w-4" /> : <PencilSquareIcon className="h-4 w-4" />}
              </Button>
            </div>

            <div className="max-display:mt-0 max-display:w-max mt-4 flex w-full flex-grow flex-col gap-4">
              <div className="flex items-center gap-4">
                <p className={classNames('whitespace-nowrap text-sm text-gray-600', isEmailEditable && 'opacity-50')}>
                  Name
                </p>
                <Input
                  className={classNames(inputClass, 'font-semibold', isEmailEditable && 'opacity-50')}
                  disabled={!isNameEditable}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNameValue(event.target.value)}
                  value={nameValue}
                />
                <Button
                  appearance="basic"
                  className={classNames(buttonClass, isNameEditable && 'py-0')}
                  disabled={isEmailEditable || (!isNameEditable && isDisabled)}
                  onClick={handleNameButtonClick}
                  type="button"
                >
                  {isNameEditable ? <CheckIcon className="h-4 w-5 rounded-full bg-[#A0CC22] p-0.5" /> : 'Edit'}
                </Button>
              </div>
              <div className="flex items-center gap-4">
                <p className={classNames('whitespace-nowrap text-sm text-gray-600', isNameEditable && 'opacity-50')}>
                  E-mail
                </p>
                <Input
                  className={classNames(inputClass, isNameEditable && 'opacity-50')}
                  disabled={!isEmailEditable}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmailValue(event.target.value)}
                  value={emailValue}
                />
                <Button
                  appearance="basic"
                  className={classNames(buttonClass, isEmailEditable && 'py-0')}
                  disabled={isNameEditable || (!isEmailEditable && isDisabled)}
                  onClick={handleEmailButtonClick}
                  type="button"
                >
                  {isEmailEditable ? <CheckIcon className="h-4 w-5 rounded-full bg-[#A0CC22] p-0.5" /> : 'Edit'}
                </Button>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="h-8 w-36">
              <img alt="Univerz footer logo" className=" h-full object-contain" src={logoFooter} />
            </div>

            <Button
              appearance="basic"
              className="flex items-center gap-1 border-0 py-0 px-0 text-xs text-[#FF231C] opacity-80 hover:bg-transparent hover:opacity-100 disabled:bg-transparent disabled:opacity-50"
              disabled={isDisabled}
              onClick={() => deleteAccountModal.open().catch(() => null)}
              shouldUseShadow={false}
              type="button"
            >
              <UserMinusIcon className="h-3 w-3" />
              <p>Delete acount</p>
            </Button>
          </div>
        </div>

        <div
          className="bg-red w-full max-w-2xl rounded-lg border border-gray-300 bg-contain bg-no-repeat p-5"
          style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundPosition: 'right bottom',
            backgroundSize: '40% 100%'
          }}
        >
          <div className="m-auto w-full max-w-md">
            <div className="mb-3 flex items-center gap-1 font-medium">
              <LockClosedIcon className="h-4 w-4 text-gray-400" />
              <p>Change password</p>
            </div>

            <Form {...{ onSubmit, schema }}>
              {({ submitting, submitError, dirtySinceLastSubmit, submitSucceeded }) => (
                <div className="flex flex-col space-y-3.5">
                  <ValidatedField
                    disabled={isDisabled}
                    field={PasswordInput}
                    id="actualPassword"
                    label={<FormattedMessage id={translations.fields.newPassword} />}
                    name="actualPassword"
                    placeholder="Actual password"
                    readOnly={submitting}
                    type="password"
                  />

                  <ValidatedField
                    disabled={isDisabled}
                    field={PasswordInput}
                    id="newPassword"
                    label={<FormattedMessage id={translations.fields.newPassword} />}
                    name="newPassword"
                    placeholder="New password"
                    readOnly={submitting}
                    type="password"
                  />

                  <ValidatedField
                    disabled={isDisabled}
                    field={PasswordInput}
                    id="repeatPassword"
                    label={<FormattedMessage id={translations.fields.repeatPassword} />}
                    name="repeatPassword"
                    placeholder="Repeat the password"
                    readOnly={submitting}
                    type="password"
                  />

                  <Button
                    appearance="primary"
                    as={LoadingButton}
                    className="m-auto disabled:opacity-50"
                    disabledButton={isDisabled}
                    loading={submitting}
                    type="submit"
                  >
                    <FormattedMessage id={translations.pages.resetPassword.cta} />
                  </Button>

                  <SubmitFeedback
                    {...{ submitError, submitSucceeded }}
                    show={!submitting && !dirtySinceLastSubmit}
                    successMessage={<FormattedMessage id={translations.pages.messages.updatedPassword} />}
                  />
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>

      <Modal
        buttonMessage={
          <>
            <UserMinusIcon className="h-3 w-3" />
            <p>Delete acount</p>
          </>
        }
        isRequiredPass={true}
        subhead={<FormattedMessage id={translations.pages.messages.deleteProfileSubhead} />}
        title={<FormattedMessage id={translations.pages.messages.deleteProfileTitle} />}
        {...deleteAccountModal.props}
      />
    </Layout>
  );
};
